import { Form, Input } from "antd";
import { useEffect } from "react";
import { getFullName } from "../../../../utils";
import { phoneValidation } from "../../../../utils/commonMethods";
import EnvelopeIcon from "../../../components/icons/EnvelopeIcon";
import PhoneIcon from "../../../components/icons/PhoneIcon";
import UserIcon from "../../../components/icons/UserIcon";

const FormItem = Form.Item;
const ParentInformation = ({ parentDetails, isEdit, form }) => {
  useEffect(() => {
    form.setFieldValue('parent_details', {
      name: parentDetails?.name,
      phone_number: parentDetails?.phone_number,
      email: parentDetails?.email
    });
  }, [parentDetails]);
  return (
    <div>
      <span className='right-sidebar-container--title'>Parent Information:</span>
      <div className='flex flex--direction-column' style={{ padding: '15px 0px 0px 0px' }}>
        {
          !isEdit ?
            <div style={{ padding: '0px 0px 0px 15px' }}>
              <div className='right-sidebar-container--subtitle flex flex--align-center'
                style={{
                  fontSize: '14px',
                  lineHeight: '16px'
                }}
              >
                <UserIcon /> {getFullName(parentDetails)}
              </div>
              <div
                className='right-sidebar-container--subtitle flex flex--align-center'
                style={{
                  fontSize: '14px',
                  lineHeight: '16px'
                }}
              >
                <PhoneIcon /> {parentDetails?.phone_number}
              </div>
              <div
                className='right-sidebar-container--subtitle flex flex--align-center'
                style={{
                  fontSize: '14px',
                  lineHeight: '16px'
                }}
              >
                <EnvelopeIcon />{parentDetails?.email}
              </div></div> :
            <div>
              <FormItem name={["parent_details", "name"]} rules={[{ required: true, message: 'Parent name is required' }]} style={{ marginBottom: '4px' }}>
                <Input prefix={<UserIcon color="#000" />} placeholder='Enter parent name' />
              </FormItem>
              <FormItem
                name={["parent_details", "phone_number"]}
                rules={[
                  {
                    validator(_, value) {
                      return phoneValidation(value);
                    },
                  },
                ]}
                style={{ marginBottom: '4px' }}>
                <Input prefix={<PhoneIcon color="#000" />} placeholder='+1XXXXXXXXXX' />
              </FormItem>
              <FormItem
                name={["parent_details", "email"]}
                rules={[
                  { required: true, message: 'Parent email is required' },
                  {
                    type: 'email',
                    message: 'Invalid email!',
                  }
                ]}
                style={{ marginBottom: '4px' }}>
                <Input prefix={<EnvelopeIcon color="#000" />} placeholder='something@xyz.com' />
              </FormItem>
            </div>
        }

      </div>
    </div >
  );
};

export default ParentInformation;
import { Modal, Form, Input, ModalFuncProps, FormInstance, Spin } from 'antd';
import { useState } from 'react';
import Button from '../../../components/Button';
import CustomMultiSelect from '../../../components/CustomMultiSelect';
import { GreenEllipse, ModalCloseIcon } from '../../../components/icons';
import { UserInformation } from '../../Dashboard/slice/types';

const FormItem = Form.Item;
interface IProps extends ModalFuncProps {
  form: FormInstance<any>;
  onFinish: ((values: any, student) => void) | undefined;
  resourcesList: any;
  studentDetails: UserInformation | null;
  loading: boolean;
}
const AssignResourceForm = (props: IProps) => {
  const { open, onCancel, onFinish, form, resourcesList, studentDetails, loading } = props;
  const [dirty, setDirty] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState<string[] | number[]>([]);

  const handleSelectedOptions = (value: string[] | number[]) => {
    setDirty(true);
    setSelectedOptions(value);
  };
  return (
    <Modal
      open={open}
      title={<><GreenEllipse />  Assign Resources </>}
      onCancel={onCancel}
      closeIcon={<ModalCloseIcon />}
      footer={[
        <Button label={"Assign"} variation="action" onClick={() => form.submit()} />,
      ]}
      {...props}
    >
      <Spin spinning={loading}>
        <Form layout="vertical"
          form={form}
          onFinish={onFinish ? (values) => onFinish(values, studentDetails?.id) : undefined}
          className="custom-form"
          requiredMark={false}
        >
          <FormItem label="Student full name" name="student">
            <Input defaultValue={studentDetails?.name || studentDetails?.username} readOnly />
          </FormItem>
          <FormItem
            label="Resources*"
            name="resource"
            rules={[{ required: true, message: 'Select the resources!' }]}>
            <CustomMultiSelect
              placeholder="Select Resources"
              selectedOptions={selectedOptions}
              selectOptions={resourcesList}
              onChange={(value: string[]) => handleSelectedOptions(value)}
              dirty={dirty}
              setDirty={setDirty}
            />
          </FormItem>
        </Form>
      </Spin>
    </Modal >
  );
};
export default AssignResourceForm;

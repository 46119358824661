import { Categories } from "../Categories";
import { Dashboard } from "../Dashboard";
import { Feedback } from "../Feedback";
import { Notifications } from "../Notifications";
import { Parents } from "../Parents";
import { Resources } from "../Resources";
import { StaffTeachers } from "../StaffTeachers";
import { Students } from "../Students";

export const menuDetails = [{
  path: '/',
  label: 'Dashboard',
  component: Dashboard
}, {
  path: '/students',
  label: 'Students',
  component: Students
}, {
  path: '/parents',
  label: 'Parents',
  component: Parents
}, {
  path: '/teachers',
  label: 'Staff - Teachers',
  component: StaffTeachers
}, {
  path: '/categories',
  label: 'Categories',
  component: Categories
}, {
  path: '/resources',
  label: 'Resources',
  component: Resources
}, {
  path: '/notifications',
  label: 'Notifications',
  component: Notifications
}, {
  path: '/feedback',
  label: 'Feedback',
  component: Feedback
}, {
  path: '/logout',
  label: 'Log out',
  component: Dashboard
}];
import { Redirect, Route } from 'react-router-dom';
import { auth } from 'utils/auth';
// import jwtDecode from 'jwt-decode';


const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={props => {
      const token = auth.getToken();
      if (token !== null) {
        // const { exp }: any = jwtDecode(token);
        // if (Date.now() >= exp * 1000) {
        //   auth.logout();
        //   return (
        //     <Redirect
        //       to={{
        //         pathname: '/login',
        //       }
        //       }
        //     />
        //   );
        // }
        return <Component {...props} />;
      } else {
        return (
          <Redirect
            to={{
              pathname: '/login',
            }
            }
          />
        );
      }
    }}
  />
);

export default PrivateRoute;

/**
*
* ParentsDetails
*
*/
import { Spin, Form } from 'antd';
import { memo, useEffect, useState } from 'react';
import { UserInformation } from '../../Dashboard/slice/types';
import { EditIcon } from '../../../components/icons';
import { useDispatch, useSelector } from 'react-redux';
import { dashboardActions } from '../../Dashboard/slice';
import { selectDashboard } from '../../Dashboard/slice/selectors';
import UserDetailsTopSection from '../../../components/UserDetailsTopSection';
import KidsCard from '../../../components/KidsCard';
import Button from '../../../components/Button';
import "../styles.scss";
import ImportCSV from '../../../components/ImportCSV';

interface Props {
  parentDetails: UserInformation;
  handleDelete: (id: any) => void;
  setOpenFormModal: any;
}

export const ParentsDetails = memo((props: Props) => {
  const { parentDetails, handleDelete, setOpenFormModal } = props;
  const [loading, setLoading] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const { parentsChildrenList } = useSelector(selectDashboard);
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  useEffect(() => {
    if (parentDetails) {
      setLoading(true);
      setIsEdit(false);
      dispatch(dashboardActions.getParentsChildren({ id: parentDetails.id }));
    }
    else
      dispatch(dashboardActions.setParentsChildren(null));
  }, [parentDetails]);

  useEffect(() => {
    if (parentsChildrenList) setLoading(false);
  }, [parentsChildrenList]);

  const handleUpdateUserInfo = (values, imageFile) => {
    setLoading(true);
    if (imageFile) values.profile_image = imageFile;
    dispatch(dashboardActions.updateUserInformation({
      id: parentDetails.id,
      data: values,
      account_type: parentDetails.account_type
    }));
    setIsEdit(false);
    form.resetFields();
  };
  return (
    <Spin spinning={loading}>
      <div className='right-sidebar-container'>
        <div style={{ width: '100%' }}>
          <UserDetailsTopSection
            userDetails={parentDetails}
            form={form}
            onFinish={handleUpdateUserInfo}
            isEdit={isEdit}
          />
          <div className='border-bottom'></div>
          <div>
            <span className='right-sidebar-container--title'>Kids Information:</span>
            <div className='flex flex--direction-column' style={{ padding: '15px 0px', rowGap: '1px' }}>
              {parentsChildrenList?.results?.map(item =>
                <KidsCard userDetails={item} key={item.id} isEdit={isEdit} handleDelete={handleDelete} />
              )}
            </div>
          </div>
          {
            !isEdit &&
            <div className='right-sidebar-container-edit-icon' onClick={() => setIsEdit(true)}><EditIcon /></div>
          }

          <>
            {isEdit &&
              <p
                className='add-new-btn'
                onClick={() => setOpenFormModal({ isModalVisible: true, selectedParent: parentDetails })}
              >+ Add New Student
              </p>
            }
            <ImportCSV account_type={"student"} parent={parentDetails.id} />
          </>
        </div>
        {isEdit ? (
          <div className='flex' style={{ columnGap: '4px', width: '100%' }}>
            <Button
              label={<span>Save</span>}
              variation="action"
              style={{ width: '100%', height: '48px' }}
              onClick={() => form.submit()}
            />
            <Button
              label={<span>Cancel</span>}
              variation="action"
              style={{ width: '100%', height: '48px', background: '#6B7579', border: '1px solid white' }}
              onClick={() => setIsEdit(false)}
            />
          </div>
        )
          :
          <div>
            <Button label={<span> Delete</span>} variation="delete" style={{ height: '48px', marginTop: '16px' }}
              onClick={() => { handleDelete(parentDetails.id); }}
            />
          </div>
        }
      </div>
    </Spin>
  );
});


import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from 'utils/@reduxjs/toolkit';
import { useInjectReducer, useInjectSaga } from 'utils/redux-injectors';
import { studentsSaga } from './saga';
import { studentsResourceListResponse, StudentsState } from './types';

export const initialState: StudentsState = {
  studentsResourceList: null,
  isAssignResource: null
};

const slice = createSlice({
  name: 'students',
  initialState,
  reducers: {
    getStudentsResourceList(state, action: PayloadAction<{ id: number | string; }>) { },
    setStudentsResourceList(state, action: PayloadAction<studentsResourceListResponse | null>) {
      state.studentsResourceList = action.payload;
    },
    setIsAssignResource(state, action: PayloadAction<boolean | null>) {
      state.isAssignResource = action.payload;
    },
    assignResourceSubmit(state, action: PayloadAction<any>) { },
    deleteAssignedResource(state, action: PayloadAction<{ data: any | number, studentId: any; }>) { },
  },
});

export const { actions: studentsActions } = slice;

export const useStudentsSlice = () => {
  useInjectReducer({ key: slice.name, reducer: slice.reducer });
  useInjectSaga({ key: slice.name, saga: studentsSaga });
  return { actions: slice.actions };
};

/**
 * Example Usage:
 *
 * export function MyComponentNeedingThisSlice() {
 *  const { actions } = useStudentsSlice();
 *
 *  const onButtonClick = (evt) => {
 *    dispatch(actions.someAction());
 *   };
 * }
 */
import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from 'utils/@reduxjs/toolkit';
import { useInjectReducer, useInjectSaga } from 'utils/redux-injectors';
import { categoriesSaga } from './saga';
import { CategoriesState } from './types';

export const initialState: CategoriesState = {

};

const slice = createSlice({
  name: 'categories',
  initialState,
  reducers: {
    addNewCategories(state, action: PayloadAction<any>) { },
    deleteCategoryById(state, action: PayloadAction<{ id: string | number; }>) { },
  },
});

export const { actions: categoriesActions } = slice;

export const useCategoriesSlice = () => {
  useInjectReducer({ key: slice.name, reducer: slice.reducer });
  useInjectSaga({ key: slice.name, saga: categoriesSaga });
  return { actions: slice.actions };
};

/**
 * Example Usage:
 *
 * export function MyComponentNeedingThisSlice() {
 *  const { actions } = useCategoriesSlice();
 *
 *  const onButtonClick = (evt) => {
 *    dispatch(actions.someAction());
 *   };
 * }
 */
import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from 'utils/@reduxjs/toolkit';
import { useInjectReducer, useInjectSaga } from 'utils/redux-injectors';
import { notificationsSaga } from './saga';
import { NotificationDetails, NotificationResponse, NotificationsState, SendNotificationRequest } from './types';

export const initialState: NotificationsState = {
  notificationList: null,
  isAddedNotification: null
};

const slice = createSlice({
  name: 'notifications',
  initialState,
  reducers: {
    getNotificationList(state, action: PayloadAction<any>) { },
    setIsAddedNotification(state, action: PayloadAction<boolean | null>) { state.isAddedNotification = action.payload; },
    setNotificationList(state, action: PayloadAction<NotificationResponse | null>) {
      state.notificationList = action.payload;
    },
    resendNotifications(state, action: PayloadAction<{ data: NotificationDetails, id: number | string; }>) { },
    sendNotifications(state, action: PayloadAction<{ data: SendNotificationRequest; }>) { },
    deleteNotificationById(state, action: PayloadAction<{ id: string | number; }>) { },
  },
});

export const { actions: notificationsActions } = slice;

export const useNotificationsSlice = () => {
  useInjectReducer({ key: slice.name, reducer: slice.reducer });
  useInjectSaga({ key: slice.name, saga: notificationsSaga });
  return { actions: slice.actions };
};

/**
 * Example Usage:
 *
 * export function MyComponentNeedingThisSlice() {
 *  const { actions } = useNotificationsSlice();
 *
 *  const onButtonClick = (evt) => {
 *    dispatch(actions.someAction());
 *   };
 * }
 */
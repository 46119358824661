import { Modal, Form, Input, ModalFuncProps, FormInstance } from 'antd';
import Button from '../../../components/Button';
import { GreenEllipse, ModalCloseIcon } from '../../../components/icons';

const FormItem = Form.Item;
interface IProps extends ModalFuncProps {
  form: FormInstance<any>;
  onFinish: ((values: any) => void) | undefined;
}
const CategoryAddForm = (props: IProps) => {
  const { open, onCancel, onFinish, form } = props;
  return (
    <Modal
      open={open}
      title={<><GreenEllipse />  Add Category </>}
      onCancel={onCancel}
      closeIcon={<ModalCloseIcon />}
      footer={[
        <Button label={"Save"} variation="action" onClick={() => form.submit()} />,
      ]}
      {...props}
    >
      <Form layout="vertical"
        form={form}
        onFinish={onFinish}
        className="custom-form"
      >
        <FormItem label="Category" name="name" rules={[{ required: true, message: 'Please input the name of category!' }]}>
          <Input />
        </FormItem>
      </Form>
    </Modal >
  );
};
export default CategoryAddForm;

import React from "react";
import "./styles.scss";
import { Checkbox, Select } from "antd";
const { Option } = Select;
const CustomMultiSelect = ({ selectedOptions, selectOptions, dirty, setDirty, ...props }) => {

  return (
    <Select
      //@ts-ignore
      onMouseDown={(e) => {
        setDirty(false);
        e.stopPropagation();
      }}
      mode="multiple"
      showArrow={true}
      showSearch
      filterOption={(inputValue, option) => {
        return option?.label?.props?.children?.toLowerCase()?.indexOf(inputValue?.toLowerCase()) >= 0;
      }
      }
      options={selectOptions?.map((item: { value: any, label: any; }) => {
        return {
          value: item.value,
          label: (<Checkbox
            onClick={(e) => {
              if (dirty) {
                e.stopPropagation();
              }
              setDirty(false);
            }}
            checked={selectedOptions.includes(item.value)}
          >
            {item.label}
          </Checkbox>)
        };
      })}
      // value={selectedOptions}
      {...props}
    />
  );
};

export default CustomMultiSelect;
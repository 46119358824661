/**
*
* StaffTeachers
*
*/
import { Col, Image, Row, Table, Form } from 'antd';
import React, { memo, useEffect, useReducer, useState } from 'react';
import { ImportOutlined } from '@ant-design/icons';
import Button from '../../components/Button';
import PageTitle from '../../components/Header/PageTitle';
import SearchInput from '../../components/Header/SearchInput';
import { Layout } from '../Layout';
// import DefaultImage from "../../../assets/icons/default-image.svg";
import { useDispatch, useSelector } from 'react-redux';
import { selectDashboard } from '../Dashboard/slice/selectors';
import { dashboardActions, useDashboardSlice } from '../Dashboard/slice';
import DeleteIcon from '../../components/icons/DeleteIcon';
import Modal from '../../components/Modal';
import { ModalTypes } from '../../../constant';
import { UserInformation } from '../Dashboard/slice/types';
import { GreenEllipse, PlusIcon } from '../../components/icons';
import { selectLayout } from '../Layout/slice/selectors';
import { TeacherDetails } from './components/TeacherDetails';
import AddTeacherForm from './components/AddTeacherForm';
import "./styles.scss";
import AssignStudentsForm from './components/AssignStudentsForm';
import { selectStaffTeachers } from './slice/selectors';
import { useStaffTeachersSlice } from './slice';
import ImportCSV from '../../components/ImportCSV';
import BulkImport from '../../components/BulkImport';
import DefaultImage from '../../components/icons/DefaultImage';

interface Props { }

export const StaffTeachers = memo((props: Props) => {
  const { teacherList, isAddedUser, studentList } = useSelector(selectDashboard);
  const { loading } = useSelector(selectLayout);
  const { teachersChildrenList, isAssignStudent } = useSelector(selectStaffTeachers);
  const dispatchRedux = useDispatch();
  const { actions } = useDashboardSlice();
  const teacherActions = useStaffTeachersSlice();
  const [search, setSearch] = useState('');
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(20);
  const [selectedTeacher, setSelectedTeacher] = useState<UserInformation | null>(null);
  const [openFormModal, setOpenFormModal] = useState(false);
  const [openAssignModal, setOpenAssignModal] = useState(false);
  const [filters, setFilters] = useState<any>({
    subjects: null,
    grades: null,
  });
  const [form] = Form.useForm();

  const ACTION_TYPES = {
    HANDLE_MODAL: 'HANDLE_MODAL',
  };
  const reducer = (state: any, action: {
    type: any;
    payload: {
      isModalVisible: boolean;
      modalTypes: any;
      modalId: string | React.Key[] | null | Number;
    };
  }) => {
    switch (action.type) {
      case ACTION_TYPES.HANDLE_MODAL:
        return {
          ...state,
          isModalVisible: action.payload.isModalVisible,
          modalTypes: action.payload.modalTypes,
          modalId: action.payload.modalId
        };
      default:
        return state;
    }
  };
  const [state, dispatch] = useReducer(reducer, {
    isModalVisible: false,
    modalTypes: ModalTypes.activate,
    modalId: null
  });
  const columns = [
    {
      title: 'Image',
      dataIndex: 'profile_image',
      width: '15%',
      render: (_, row) => {
        return (
          row.profile_image ?
            <Image
              src={row.profile_image}
              alt={row.name}
              height={34}
              width={34}
              style={{ borderRadius: '50%' }}
              preview={false}
            />
            :
            <DefaultImage />);
      }
    },
    {
      title: 'Name',
      dataIndex: 'name',
      width: '30%',
    },
    {
      title: 'Email',
      dataIndex: 'email',
      width: '30%',
    },
    // {
    //   title: 'Subject',
    //   dataIndex: 'subjects',
    //   width: '20%',
    //   render: (_, row) => (
    //     <div>
    //       <div className='flex'>
    //         <div className='feedback-message'>
    //           <span>
    //             {/* {subjectList?.results?.filter(item => row.subjects.includes(item.id))?.map(item => item.name).join(', ')} */}
    //             {subjectList?.results?.find(item => row.subjects[0] === item.id)?.name}
    //           </span>
    //         </div>
    //         {
    //           row.subjects.length > 1 && <Tooltip placement="topLeft"
    //             title={subjectList?.results?.filter(item => row.subjects.includes(item.id))?.map(item => item.name).join(', ')}>
    //             <div style={{ marginLeft: '6px' }}><InfoCircleOutlined role={"button"} /></div>
    //           </Tooltip>
    //         }
    //       </div >
    //     </div>
    //   ),

    //   filterIcon: () => <DropdownIcon />,
    //   filters: subjectList?.results?.map(item => { return { text: item.name, value: item.id }; })
    // },
    // {
    //   title: 'Grade',
    //   dataIndex: 'grades',
    //   width: '18%',
    //   render: (_, row) => (
    //     <div>{schoolClassList?.results?.find(item => item.id === row.grade)?.name}</div>
    //   ),
    //   filterIcon: () => <DropdownIcon />,
    //   filters: schoolClassList?.results?.map(item => { return { text: item.name, value: item.id }; })
    // },
    {
      title: 'Action',
      render: (_: any, row: UserInformation) => {
        return (
          <div className='table-action-btn'>
            {
              !row.is_active ? <Button
                key={`activate-${row.id}`}
                variation="primary"
                label={"Activate"}
                onClick={() => {
                  dispatch({
                    type: ACTION_TYPES.HANDLE_MODAL,
                    payload: { isModalVisible: true, modalTypes: ModalTypes.activate, modalId: row.id },
                  });
                }
                }
              />
                :
                <Button
                  key={`action-${row.id}`}
                  label="Deactivate"
                  variation='secondary'
                  onClick={() => {
                    dispatch({
                      type: ACTION_TYPES.HANDLE_MODAL,
                      payload: { isModalVisible: true, modalTypes: ModalTypes.deactivate, modalId: row.id },
                    });
                  }
                  }
                />
            }
            <div
              className='search-icon' onClick={() => {
                // dispatch({
                //   type: ACTION_TYPES.HANDLE_MODAL,
                //   payload: { isModalVisible: true, modalTypes: ModalTypes.remove, modalId: row.id },
                // });
                handleDelete(row.id);
              }}>
              <DeleteIcon />
            </div>
          </div>
        );
      },
      width: '25%',
    },
  ];
  useEffect(() => {
    dispatchRedux(actions.getUserList({
      search: search, page: page, pageSize: pageSize, account_type: 'teacher'
    }));
    dispatchRedux(actions.getSchoolClassList());
    dispatchRedux(actions.getSubjectList());
  }, []);
  useEffect(() => {
    dispatchRedux(actions.getUserList({
      search: search,
      page: page,
      pageSize: pageSize,
      account_type: 'teacher',
      grades: filters.grades,
      subjects: filters.subjects
    }));
  }, [search, page, filters, pageSize]);

  useEffect(() => {
    if (teacherList?.results) {
      setSelectedTeacher(teacherList?.results[0]);
      dispatchRedux(actions.getUserList({
        account_type: 'student'
      }));
    }
  }, [teacherList]);
  useEffect(() => {
    if (isAddedUser) {
      setOpenFormModal(false);
      form.resetFields();
      dispatchRedux(dashboardActions.setIsAddedUser(null));
    }
  }, [isAddedUser]);

  useEffect(() => {
    if (isAssignStudent) {
      setOpenAssignModal(false);
      form.resetFields();
      dispatchRedux(teacherActions.actions.setIsAssignStudent(null));
    }
  }, [isAssignStudent]);
  const handleSearch = (val: React.SetStateAction<string>) => {
    setSearch(val);
  };

  const handleAddNewTeacher = (values) => {
    values.account_type = 'teacher';
    dispatchRedux(dashboardActions.addNewTeacherSubmit({ data: values }));
  };
  const handleCancel = () => {
    dispatch({
      type: ACTION_TYPES.HANDLE_MODAL,
      payload: { isModalVisible: false, modalTypes: ModalTypes.activate, modalId: null },
    });
  };
  const handleOk = (status: 'activate' | 'deactivate' | 'remove' | 'remove all') => {
    if (status === 'remove')
      dispatchRedux(actions.deleteUserInformation({ username: state.modalId, account_type: 'teacher' }));
    else if (status === 'remove all') {
      dispatchRedux(actions.deleteSelectedUser({ user_list: state.modalId, account_type: 'teacher' }));
      setSelectedRowKeys([]);
    }
    else
      dispatchRedux(actions.updateUserStatus({ username: state.modalId, status: status }));
    dispatch({
      type: ACTION_TYPES.HANDLE_MODAL,
      payload: { isModalVisible: false, modalTypes: ModalTypes.activate, modalId: null },
    });
  };
  const handleDelete = (id) => {
    dispatch({
      type: ACTION_TYPES.HANDLE_MODAL,
      payload: { isModalVisible: true, modalTypes: ModalTypes.remove, modalId: id },
    });
  };
  // Table Row Selection
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const onChange = (pagination, filter, sorter, extra) => {
    setFilters({
      ...filters,
      grades: filter.grades,
      subjects: filter.subjects
    });
  };
  const handleAssignStudents = (values, teacher) => {
    console.log("values");
    const data = {
      students: values?.students,
      teacher: teacher
    };
    dispatchRedux(teacherActions.actions.assignStudentSubmit({ data: data, teacher: teacher }));
  };
  return (
    <Layout>
      <Row className="header-container flex--justify-between" style={{ marginTop: '16px', columnGap: '12px' }}>
        <Col span={4}>
          <PageTitle title={"Staff Teachers"} subtitle={''} titleIcon={<GreenEllipse />} />
        </Col>
        <Col span={7}>
          <SearchInput handleSearch={handleSearch} />
        </Col>
        <Col span={3}>
          {selectedRowKeys.length > 0 ?
            <Button
              label={<span><DeleteIcon color='white' /> Delete</span>}
              variation="delete"
              style={{ width: '100%', height: '48px', padding: '0px' }}
              onClick={() => {
                dispatch({
                  type: ACTION_TYPES.HANDLE_MODAL,
                  payload: { isModalVisible: true, modalTypes: ModalTypes.removeall, modalId: selectedRowKeys },
                });
              }} /> :
            <Button
              label={<span><PlusIcon /> Add New</span>}
              variation="action"
              style={{ width: '100%', height: '48px', background: '#424242', padding: '1px' }}
              onClick={() => setOpenFormModal(true)}
            />}
        </Col>
        <Col span={4}>
          <ImportCSV account_type={"teacher"} />
        </Col>
        <Col span={4}>
          <BulkImport account_type={"teacher"} />
        </Col>
      </Row>
      <Row className='students-main-container'>
        <Col span={16}>
          <Table
            loading={loading}
            columns={columns}
            dataSource={teacherList?.results}
            pagination={{
              current: page,
              total: teacherList?.count,
              onChange(page, pageSize) {
                setPage(page);
                setPageSize(pageSize);
              },
              pageSize: pageSize
            }}
            rowSelection={rowSelection}
            rowKey={obj => obj.username.toString()}
            onChange={onChange}
            onRow={(record) => {
              return {
                onClick: () => {
                  setSelectedTeacher(record);
                }, // click row
              };
            }}
          />
        </Col>
        <Col span={8} style={{ paddingLeft: '15px' }}>
          {
            selectedTeacher &&
            <TeacherDetails
              teacherDetails={selectedTeacher}
              handleDelete={handleDelete}
              setOpenFormModal={setOpenAssignModal}
            />
          }
        </Col>
      </Row>
      <Modal
        handleCancel={handleCancel}
        handleOk={() => handleOk(state.modalTypes.title.toLowerCase())}
        isModalVisible={state.isModalVisible}
        message={state.modalTypes.message}
        title={<><GreenEllipse /> {state.modalTypes.title}</>}
        btnTitle={state.modalTypes.btnTitle}
      />
      {openFormModal &&
        <AddTeacherForm
          form={form}
          loading={loading}
          onFinish={handleAddNewTeacher}
          onCancel={() => {
            setOpenFormModal(false);
            form.setFieldsValue({});
          }}
          open={openFormModal}
          // gradeOptions={schoolClassList?.results?.map(item => { return { value: item.id, label: item.name }; })}
          // subjectOptions={subjectList?.results?.map(item => { return { value: item.id, label: item.name }; })}
          studentOptions={studentList?.results?.map(item => { return { value: item.id, label: item.name || item.username }; })}
        />
      }
      {
        openAssignModal &&
        <AssignStudentsForm
          loading={loading}
          onFinish={handleAssignStudents}
          open={openAssignModal}
          form={form}
          teacherDetails={selectedTeacher}
          onCancel={() => setOpenAssignModal(false)}
          studentsList={studentList?.results?.filter(obj => {
            return !teachersChildrenList?.results?.some(filterObj => filterObj.id === obj.id);
          }).map(item => { return { value: item.id, label: item.name }; })}
        />
      }
    </Layout >
  );

});
;

import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from 'utils/@reduxjs/toolkit';
import { useInjectReducer, useInjectSaga } from 'utils/redux-injectors';
import { staffTeachersSaga } from './saga';
import { StaffTeachersState, TeachersChildrenResponse } from './types';

export const initialState: StaffTeachersState = {
  teachersChildrenList: null,
  isAssignStudent: null
};

const slice = createSlice({
  name: 'staffTeachers',
  initialState,
  reducers: {
    getTeachersChildren(state, action: PayloadAction<{ id: string | number; }>) { },
    setTeachersChildren(state, action: PayloadAction<TeachersChildrenResponse | null>) {
      state.teachersChildrenList = action.payload;
    },
    assignStudentSubmit(state, action: PayloadAction<any>) { },
    setIsAssignStudent(state, action: PayloadAction<boolean | null>) {
      state.isAssignStudent = action.payload;
    },
  },
});

export const { actions: staffTeachersActions } = slice;

export const useStaffTeachersSlice = () => {
  useInjectReducer({ key: slice.name, reducer: slice.reducer });
  useInjectSaga({ key: slice.name, saga: staffTeachersSaga });
  return { actions: slice.actions };
};

/**
 * Example Usage:
 *
 * export function MyComponentNeedingThisSlice() {
 *  const { actions } = useStaffTeachersSlice();
 *
 *  const onButtonClick = (evt) => {
 *    dispatch(actions.someAction());
 *   };
 * }
 */
import { AxiosRequestConfig } from 'axios';
import { call, put, takeLatest, delay } from 'redux-saga/effects';
import { parentsActions as actions } from '.';
import request from '../../../../utils/request';
import { dashboardActions } from '../../Dashboard/slice';
import { layoutActions } from '../../Layout/slice';

function* addParentsSubmit({ payload }) {
  yield delay(500);
  yield put(layoutActions.setLoading(true));
  const { data } = payload;
  let url = `/parents/create/`;
  const options: AxiosRequestConfig = {
    method: 'POST',
    data: data
  };
  try {
    yield call(request, url, options);
    yield put(dashboardActions.getUserList({
      search: '', page: 1, pageSize: 20, account_type: 'parent'
    }));
    yield put(layoutActions.setLoading(false));
    yield put(actions.setIsAdded(true));
    yield put(layoutActions.setSuccess("Parent added successfully"));
  } catch (ex: any) {
    yield put(layoutActions.setError(ex.message));
    yield put(layoutActions.setLoading(false));
  }
}

function* addStudentsToParentSubmit({ payload }) {
  yield delay(500);
  yield put(layoutActions.setLoading(true));
  const { data } = payload;
  let url = `/parents/add-students/`;
  const options: AxiosRequestConfig = {
    method: 'POST',
    data: data
  };
  try {
    yield call(request, url, options);
    yield put(dashboardActions.getUserList({
      search: '', page: 1, pageSize: 20, account_type: 'parent'
    }));
    yield put(layoutActions.setLoading(false));
    yield put(actions.setIsAdded(true));
    yield put(layoutActions.setSuccess("Students added successfully"));
  } catch (ex: any) {
    yield put(layoutActions.setError(ex.message));
    yield put(layoutActions.setLoading(false));
  }
}

export function* parentsSaga() {
  yield takeLatest(actions.addParentsSubmit, addParentsSubmit);
  yield takeLatest(actions.addStudentsToParent, addStudentsToParentSubmit);
}

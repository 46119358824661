import React from "react";
import { Image } from 'antd';
// import DefaultImage from "assets/icons/default-image.svg";
import { getFullName } from "../../../utils";
import EnvelopeIcon from "../icons/EnvelopeIcon";
import PhoneIcon from "../icons/PhoneIcon";
import "./styles.scss";
import DeleteIcon from "../icons/DeleteIcon";
import DefaultImage from "../icons/DefaultImage";

const KidsCard = ({ userDetails, isEdit = false, handleDelete }) => (
  <div className='flex flex--align-center kids-card-container'>
    <div>
      {/* <Image
        src={userDetails.profile_image || DefaultImage}
        alt={userDetails.name}
        height={55}
        width={55}
        style={{ borderRadius: '50%' }}
        preview={false}
      /> */}
      {userDetails.profile_image ?
        <Image
          src={userDetails.profile_image}
          alt={userDetails.name}
          height={55}
          width={55}
          style={{ borderRadius: '50%' }}
          preview={false}
        />
        :
        <DefaultImage width={55} height={55} />
      }
    </div>
    <div style={{ width: '100%' }}>
      <div className='screen-main-title flex flex--justify-between flex--align-center'>
        {getFullName(userDetails)}
        {isEdit && <div style={{ cursor: 'pointer' }} onClick={() => handleDelete(userDetails.id)}><DeleteIcon /></div>}
      </div>
      <div className='flex flex--justify-between flex--wrap-wrap' style={{ columnGap: '10px' }}>
        {
          userDetails?.phone_number &&
          <div className='kids-card-container--subtitle flex' style={{ color: '#424242' }}><PhoneIcon color="#424242" /> {userDetails?.phone_number}</div>
        }
        {
          userDetails?.email &&
          <div className='right-sidebar-container--subtitle flex' style={{ color: '#424242' }}><EnvelopeIcon color="#424242" />{userDetails?.email}</div>
        }

      </div>
    </div>
  </div>
);

export default KidsCard;
import dayjs from 'dayjs';
import type { UploadFile } from 'antd/es/upload/interface';
import {
  isPossiblePhoneNumber,
  isValidPhoneNumber,
} from 'libphonenumber-js';

export const AllowedFileFormat = ['png', 'jpg', 'jpeg', 'svg', 'csv', 'gif', 'pdf', 'doc', 'docx', 'xls', 'xlsx'];

export const formatDate = date => {
  return date ? dayjs(date).format('DD, MMMM, YYYY') : '';
};

export const hyphenFormatDate = date => {
  return date ? dayjs(date).format('YYYY-MM-DD') : '';
};

export const formatFileSize = (size: number): string => {
  if (size < 1000 * 1024) {
    return Math.round(size / 1000) + ' KB';
  }
  return Math.round(size / 1000 / 1024) + ' MB';
};

export function fileNameShortener(str) {
  if (str.length > 60) {
    return str.substr(0, 30) + '...' + str.substr(str.length - 20, str.length);
  }
  return str;
}

export const displayGreeting = () => {

  const myDate = new Date();
  const hrs = myDate.getHours();
  let greet;

  if (hrs < 12)
    greet = 'Good Morning';
  else if (hrs >= 12 && hrs <= 17)
    greet = 'Good Afternoon';
  else if (hrs >= 17 && hrs <= 24)
    greet = 'Good Evening';
  return greet;
};

export const dataToFormData = data => {
  const formData = new FormData();
  for (const key in data) {
    if (Object.hasOwnProperty.call(data, key)) {
      if (Array.isArray(data[key])) {
        data[key].forEach((item, index) => {
          formData.append(`${key}[${index}]`, item);
        });
      }
      else {
        const element = data[key];
        formData.append(key, element);
      }
    }
  }

  return formData;
};

export const phoneValidation = (phoneNumber) => {
  if (phoneNumber && isPossiblePhoneNumber(phoneNumber) && isValidPhoneNumber(phoneNumber)) {
    return Promise.resolve();
  }
  return Promise.reject('Invalid phone number');
};

export const validateFileType = (
  { name, size }: UploadFile,
  allowedTypes?: string[],
): boolean => {
  const extension = name.split('.').at(-1);
  if (!allowedTypes) {
    return true;
  }

  if (extension) {
    return allowedTypes.includes(extension.toLowerCase());
  }

  return true;
};

// export const validateFileSize = ({ size }: UploadFile, maxSize = 3) => {
//   return size && size / 1024 / 1024 < maxSize;
// };

// export const CustomFileValidator = (value: any, maxSize = 3) => {
//   if (value && validateFileType(value.file, AllowedFileFormat) && validateFileSize(value.file, maxSize)) {
//     return Promise.resolve();
//   }
//   if (value && !validateFileType(value.file, AllowedFileFormat))
//     return Promise.reject(
//       new Error(`Invalid attachement format! Accepted attachment format - ${AllowedFileFormat.join(', ')}!`));
//   else if (value && !validateFileSize(value.file, maxSize))
//     return Promise.reject(
//       new Error(`File size must not greater than ${maxSize}MB!`));
//   return Promise.resolve();
// };


/**
 * Validates file type and size
 * @param value Object with file property
 * @param maxSize Maximum size of the file in MB
 * @returns Promise with validation result
 */
export const CustomFileValidator = (value: any | undefined, maxSize = 3): Promise<void> => {
  if (!value) {
    return Promise.resolve();
  }
  const { file } = value;
  const fileTypeValid = validateFileType(file, AllowedFileFormat);
  const fileSizeValid = validateFileSize(file, maxSize);

  if (fileTypeValid && fileSizeValid) {
    return Promise.resolve();
  }

  if (!fileTypeValid) {
    return Promise.reject(new Error(`Invalid attachment format! Accepted attachment formats: ${AllowedFileFormat.join(', ')}. Received: ${file.type}`));
  }

  return Promise.reject(new Error(`File size must not be greater than ${maxSize}MB. Received: ${(file.size / (1024 * 1024)).toFixed(2)} MB`));
};

/**
 * Validates file type
 * @param file File object
 * @param allowedFormats Allowed file formats
 * @returns Whether file type is valid or not
 */
// export const validateFileType = (file: File, allowedFormats: string[]): boolean => {
//   const fileType = file.type;
//   return allowedFormats.some((format) => fileType.endsWith(format));
// };

/**
 * Validates file size
 * @param file File object
 * @param maxSize Maximum allowed file size in MB
 * @returns Whether file size is valid or not
 */
export const validateFileSize = (file: UploadFile, maxSize: number): boolean => {
  if (file?.size) {
    const fileSize = file?.size / (1024 * 1024); // in MB
    return fileSize <= maxSize;
  }
  return false;
};


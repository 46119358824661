interface IProps {
  title: string,
  subtitle: string;
  titleIcon: React.ReactNode | null;
}
const PageTitle = (props: IProps) => (
  <div>
    <div className="home-title" style={{ minWidth: '200px' }}>{props.titleIcon}{props.title}</div>
    <div className="home-subtitle">{props.subtitle}</div>
  </div>
);

export default PageTitle;
/**
*
* Feedback
*
*/
import { InfoCircleOutlined } from '@ant-design/icons';
import { Col, Row, Table } from 'antd';
import React, { memo, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { auth } from '../../../utils/auth';
import Button from '../../components/Button';
import PageTitle from '../../components/Header/PageTitle';
import SearchInput from '../../components/Header/SearchInput';
import { GreenEllipse } from '../../components/icons';
import PopoverContent from '../../components/PopoverContent';
import { Layout } from '../Layout';
import { selectLayout } from '../Layout/slice/selectors';
import { ReplyMessage } from './components/ReplyMessage';
import { useFeedbackSlice } from './slice';
import { selectFeedback } from './slice/selectors';
import { FeedbackDetails, FeedbackReplyRequest } from './slice/types';
import "./styles.scss";

interface Props { }

export const Feedback = memo((props: Props) => {
  const { feedbackList } = useSelector(selectFeedback);
  const { loading } = useSelector(selectLayout);
  const dispatch = useDispatch();
  const { actions } = useFeedbackSlice();
  const [search, setSearch] = useState('');
  const [page, setPage] = useState(1);
  const [pageSize] = useState(20);
  const currentUser = auth.getUserData();

  const handleSearch = (val: React.SetStateAction<string>) => {
    setSearch(val);
  };

  const handleReplyMessage = (value, form) => {
    form.resetFields();
    const submitData: FeedbackReplyRequest = {
      id: value.id,
      data: {
        message: value.message,
        reply_by: {
          email: currentUser.email,
          name: currentUser.name,
          phone_number: currentUser.phone_number,
          username: currentUser.username
        }
      }
    };
    dispatch(actions.sendFeedbackReply(submitData));
  };

  // UseEffects
  useEffect(() => {
    dispatch(actions.getFeedbackList({
      search: search, page: page, pageSize: pageSize
    }));
  }, []);
  useEffect(() => {
    dispatch(actions.getFeedbackList({
      search: search, page: page, pageSize: pageSize
    }));
  }, [search, page]);

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      width: '15%',
      render: (_, row: FeedbackDetails) => (
        <div>{row?.sender?.name}</div>
      )
    },
    {
      title: 'Email',
      dataIndex: 'email',
      width: '15%',
      render: (_, row: FeedbackDetails) => (
        <div>{row?.sender?.email}</div>
      )
    },
    {
      title: 'Subject',
      dataIndex: 'subject',
      width: '15%',
    },
    {
      title: 'Message',
      dataIndex: 'message',
      width: '45%',
      render: (_, row: FeedbackDetails) => (
        <div className='flex'>
          <div className='feedback-message'>
            <span>
              {row?.message}
            </span>
          </div>
          <PopoverContent
            content={<div><span style={{ color: '#7E8E51', fontWeight: '700' }}>Message: </span> <br />{row.message}</div>}
            title={<div><span style={{ color: '#7E8E51', fontWeight: '700' }}>Subject:  </span>{row.subject}</div>}
            placement="bottomRight"
            trigger={"hover"}
          >
            <div><InfoCircleOutlined role={"button"} /></div>
          </PopoverContent>
        </div >
      )
    },
    {
      title: 'Action',
      render: (_: any, row: FeedbackDetails) => {
        return (
          <div className='table-action-btn'>
            <PopoverContent
              content={<ReplyMessage handleReplyMessage={handleReplyMessage} id={row.id} />}
              title={<div style={{ fontSize: '20px' }} className={"home-title"}><GreenEllipse />Reply</div>}
              placement="bottomRight"
              trigger={"hover"}
            >
              <Button
                key={`action-${row.id}`}
                label="Reply"
                variation='secondary'
              />
            </PopoverContent>
          </div>
        );
      },
      width: '10%',
    },
  ];
  return (
    <Layout>
      <Row className="header-container flex--justify-between" style={{ marginTop: '16px' }}>
        <Col span={6}>
          <PageTitle title={"Feedback"} subtitle={''} titleIcon={<GreenEllipse />} />
        </Col>
        <Col span={17}>
          <SearchInput handleSearch={handleSearch} />
        </Col>
      </Row>
      <Row className='students-main-container'>
        <Col span={24}>
          <Table
            tableLayout='fixed'
            loading={loading}
            columns={columns}
            dataSource={feedbackList?.results}
            pagination={{
              current: page,
              total: feedbackList?.count || 0,
              onChange(page) {
                setPage(page);
              },
              pageSize: pageSize
            }}
            rowKey={obj => obj.id.toString()}
          />
        </Col>
      </Row>
    </Layout>
  );

});


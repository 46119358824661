import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectLayout, selectLoading } from "./slice/selectors";
import { Layout as AntLayout, Menu, MenuProps, notification, Spin } from 'antd';
import { useLayoutSlice } from "./slice";

import { CategoriesIcon, DashboardIcon, FeedbackIcon, LogoutIcon, NotificationsIcon, ParentsIcon, ResourcesIcon, StaffsIcon, StudentsIcon } from "../../components/icons";
import { useHistory } from "react-router-dom";
import { menuDetails } from "./constant";
import { auth } from "../../../utils/auth";

import Ellipse from "../../components/icons/Ellipse";
import CompanyLogo from "../../../assets/icons/company-logo.svg";
import "./styles.scss";

interface Props {
  children: any;
}

export function Layout(props: Props) {
  const { Content, Sider } = AntLayout;

  const { actions } = useLayoutSlice();
  const dispatch = useDispatch();
  const { loading, success, error } = useSelector(selectLayout);

  const history = useHistory();
  const selectedKey = history.location.pathname;

  const highlight = () => {
    let key = ['1'];
    menuDetails.forEach((menu, index) => {
      if (selectedKey === menu.path) {
        key = [String(index + 1)];
      }
    });
    return key;
  };


  const items: MenuProps['items'] = [
    DashboardIcon,
    StudentsIcon,
    ParentsIcon,
    StaffsIcon,
    CategoriesIcon,
    ResourcesIcon,
    NotificationsIcon,
    FeedbackIcon,
    LogoutIcon
  ].map((icon, index) => ({
    key: String(index + 1),
    icon: React.createElement(icon),
    label: menuDetails[index].label,
    onClick: () => menuDetails[index].path !== "/logout" ? history.push(menuDetails[index].path) : auth.logout()
  }));

  useEffect(() => {
    setTimeout(() => {
      dispatch(actions.setLoading(false));
    }, 3000);
  }, []);

  useEffect(() => {
    if (error) {
      notification.error({
        message: 'Error',
        description: error
      });
      dispatch(actions.setError(null));
    }
  }, [error]);

  useEffect(() => {
    if (success) {
      notification.success({
        message: 'Success',
        description: success,
        key: 'success-message'
      });
      dispatch(actions.setSuccess(null));
    }
  }, [success]);

  return (
    <AntLayout className="layout">
      <Sider
        style={{
          overflow: 'auto',
          position: 'fixed',
          left: 0,
          top: 0,
          bottom: 0,
          background: '#7E8E51'
        }}
        width={300}
      >
        <div style={{ margin: '30px 24px 16px', display: 'flex', alignItems: 'center', flexDirection: 'column', rowGap: '30px' }}>
          <img src={CompanyLogo} alt="Logo" style={{ background: 'white', borderRadius: '50%' }} />
          <div style={{ borderBottom: '1px solid #758548', width: '100%' }} />
        </div>
        <div className="sidebar-container">
          <Menu defaultSelectedKeys={['1']} items={items} selectedKeys={highlight()} />
          <Ellipse />
        </div>
      </Sider>
      <Content className="main-section">
        {/* <Spin spinning={loading}> */}
        {props.children}
        {/* </Spin> */}
      </Content>
    </AntLayout >
  );
}
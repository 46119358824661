import { Modal, Form, Input, ModalFuncProps, FormInstance, Row, Col, Spin, Select } from 'antd';
import Button from '../../../components/Button';
import { GreenEllipse, ModalCloseIcon } from '../../../components/icons';
import "../styles.scss";
import CustomMultiSelect from '../../../components/CustomMultiSelect';
import { useState } from 'react';
import { phoneValidation } from '../../../../utils/commonMethods';

const FormItem = Form.Item;
interface IProps extends ModalFuncProps {
  form: FormInstance<any>;
  onFinish: ((values: any) => void);
  loading: boolean;
  // subjectOptions: { value: string | number, label: string; }[] | undefined;
  // gradeOptions: { value: string | number, label: string; }[] | undefined;
  studentOptions: { value: string | number, label: string; }[] | undefined;
}
const AddTeacherForm = (props: IProps) => {
  const { open, onCancel, onFinish, form, loading, studentOptions } = props;
  const [dirty, setDirty] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState<string[] | number[]>([]);

  const handleSelectedOptions = (value: string[] | number[]) => {
    setDirty(true);
    setSelectedOptions(value);
  };
  return (
    <Modal
      open={open}
      title={<><GreenEllipse />  {"Add Teacher"} </>}
      onCancel={onCancel}
      closeIcon={<ModalCloseIcon />}
      footer={[
        <Button label={"Add"} variation="action" onClick={() => form.submit()} />,
      ]}
      className="custom-modal"
      destroyOnClose={true}
      style={{ width: '600px' }}
      {...props}
    >
      <Spin spinning={loading}>
        <Form
          preserve={false}
          layout="vertical"
          form={form}
          onFinish={onFinish}
          className="custom-add-form"
          key={"form"}
          initialValues={{ children: [""] }}
          requiredMark={false}
        >
          <Row gutter={20} key="first">
            <Col span={8}>
              <FormItem
                label="Teacher full name*"
                name="name"
                rules={[{ required: true, message: 'Enter the name!' }]}>
                <Input
                  placeholder="Enter full name"
                />
              </FormItem>
            </Col>
            <Col span={8}>
              <FormItem
                label="Email*"
                name="email"
                rules={[
                  { required: true, message: 'Enter the email!' },
                  {
                    type: 'email',
                    message: 'Invalid email!',
                  },
                ]}>
                <Input
                  placeholder="Enter email"
                  type='email'
                />
              </FormItem>
            </Col>
            <Col span={8}>
              <FormItem
                label="Phone number*"
                name="phone_number"
                rules={[
                  // { required: true, message: 'Enter the parent phone number!' },
                  {
                    validator(_, value) {
                      return phoneValidation(value);
                    },
                  },
                ]}>
                <Input
                  placeholder="Enter phone number"
                />
              </FormItem>
            </Col>
          </Row>
          <Row gutter={20} key="second">
            <Col span={8}>
              <FormItem
                label="Students*"
                name="students"
                rules={[{ required: true, message: 'Select the students!' }]}>
                <CustomMultiSelect
                  placeholder="Select Students"
                  selectedOptions={selectedOptions}
                  selectOptions={studentOptions}
                  onChange={(value: string[]) => handleSelectedOptions(value)}
                  dirty={dirty}
                  setDirty={setDirty}
                />
              </FormItem>
            </Col>
            {/* <Col span={8}>
              <FormItem
                label="Grades*"
                name="grade"
                rules={[{ required: true, message: 'Select the grade!' }]}
              >
                <Select
                  options={gradeOptions}
                  className="custom-select"
                  placeholder="Select Grades"
                />
              </FormItem>
            </Col> */}
          </Row>
        </Form>
      </Spin>
    </Modal >
  );
};
export default AddTeacherForm;

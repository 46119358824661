interface IProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  color?: string;
  props?: any;
}
const PlusIcon = ({ color = "white", props }: IProps) => (
  <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M12.7277 8.07043L8.07053 8.07037L8.07058 12.7275C8.07058 13.28 7.62275 13.7278 7.0703 13.7278C6.79502 13.7278 6.54425 13.6165 6.36325 13.4355C6.18224 13.2545 6.07 13.0047 6.07094 12.7284L6.071 8.07129L1.41385 8.07135C1.13857 8.07135 0.887804 7.96005 0.706798 7.77904C0.525792 7.59804 0.41355 7.34821 0.414493 7.07199C0.414493 6.51954 0.862328 6.0717 1.41477 6.0717L6.07192 6.07176L6.07186 1.41462C6.07186 0.86217 6.5197 0.414335 7.07214 0.414335C7.62459 0.414335 8.07243 0.86217 8.07243 1.41462L8.07237 6.07176L12.7296 6.07176C13.282 6.07176 13.7298 6.51954 13.7298 7.07199C13.7298 7.62443 13.2801 8.07043 12.7277 8.07043Z" fill={color} />
  </svg>

);

export default PlusIcon;
import { Redirect, Route } from 'react-router-dom';
import { auth } from 'utils/auth';

const PublicRoute = ({ component: Component, restricted = false, ...rest }) => (
  <Route
    {...rest}
    render={props => {
      if (auth.isLogin() && restricted) {
        return (
          <Redirect
            to={{
              pathname: '/',
              state: { from: props.location },
            }}
          />
        );
      } else {
        return <Component {...props} />;
      }
    }}
  />
);

export default PublicRoute;

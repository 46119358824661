export default function parseDjangoError(errors) {
  let errorsList = [];
  if (typeof errors === 'string') {
    const parser = new DOMParser();
    const res = parser.parseFromString(errors, 'text/xml');
    return res?.firstChild?.innerHTML;
  }
  if (Array.isArray(errors)) {
    errors.forEach(err => {
      for (const key in err) {
        if (Object.hasOwnProperty.call(err, key)) {
          const element = err[key];
          if (typeof element === 'string') {
            errorsList.push(element);
          } else if (typeof element === 'object') {
            element.forEach(e => {
              errorsList.push(e);
            });
          }
        }
      }
    });
  }
  else {
    for (const key in errors) {
      if (Object.hasOwnProperty.call(errors, key)) {
        const element = errors[key];
        if (typeof element === 'string') {
          errorsList.push(element);
        } else if (typeof element === 'object') {
          element.forEach(e => {
            errorsList.push(e);
          });
        }
      }
    }
  }
  if (errorsList.length === 1) {
    return errorsList[0];
  } else {
    return (
      <ul>
        {
          errorsList.map(e => (
            <li>{e} </li>
          ))
        }
      </ul>
    );
  }
}

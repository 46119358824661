import { AxiosRequestConfig } from 'axios';
import { call, put, takeLatest, delay } from 'redux-saga/effects';
import { feedbackActions as actions } from '.';
import request from '../../../../utils/request';
import { layoutActions } from '../../Layout/slice';

function* fetchFeedbackList({ payload }) {
  yield delay(500);
  yield put(layoutActions.setLoading(true));
  const { search, page, pageSize } = payload;

  let url = `/users/send-support-message/?search=${encodeURIComponent(
    search,
  )}${page ? '&page=' + page : ''}&pageSize=${pageSize}`;
  const options: AxiosRequestConfig = {
    method: 'GET',
  };
  try {
    const response = yield call(
      request,
      url,
      options,
    );
    yield put(actions.setFeedbackList(response));
    yield put(layoutActions.setLoading(false));
  } catch (ex: any) {
    yield put(layoutActions.setError(ex.message));
    yield put(layoutActions.setLoading(false));
  }
}

function* sendFeedbackReply({ payload }) {
  const { id, data } = payload;
  yield delay(500);
  yield put(layoutActions.setLoading(true));
  const options: AxiosRequestConfig = {
    method: 'POST',
    data: data
  };
  try {
    yield call(
      request,
      `users/send-support-message/${id}/reply/`,
      options,
    );
    yield put(layoutActions.setSuccess("Replied successfully"));
    yield put(layoutActions.setLoading(false));
  } catch (ex: any) {
    yield put(layoutActions.setError(ex.message));
    yield put(layoutActions.setLoading(false));
  }
}

export function* feedbackSaga() {
  yield takeLatest(actions.getFeedbackList, fetchFeedbackList);
  yield takeLatest(actions.sendFeedbackReply, sendFeedbackReply);
}

import { Image } from "antd";
import PageTitle from "./PageTitle";
import SearchInput from "./SearchInput";
import banner from "../../../assets/banner.svg";
import { displayGreeting } from "../../../utils/commonMethods";

import './styles.scss';
import Banner from "../icons/Banner";

interface IProps {
  title: string | "Dashboard",
  handleSearch: (val: string) => void;
  subtitle: string;
  titleIcon?: React.ReactNode;
}
const Header = (props: IProps) => {
  return (
    <>
      <div className="header-banner">
        {/* <Image src={banner} alt="banner" preview={false} /> */}
        <Banner />
        <div className="header-banner-title">
          <div className="home-greetings">
            <span style={{ color: '#7E8E51' }}>{displayGreeting()}, </span>
            <span>{props.title}</span>
          </div>
          <div className="home-subtitle">Have a nice day at work</div>
        </div>
      </div>
      <div className="header-container flex--justify-between">
        <PageTitle title={"Dashboard"} subtitle={props.subtitle} titleIcon={props.titleIcon} />
        <SearchInput handleSearch={props.handleSearch} style={{ maxWidth: '470px' }} />
      </div>
    </>
  );
};

export default Header;
import { Modal, Form, Input, ModalFuncProps, FormInstance, Select, Row, Col, Spin } from 'antd';
import { useEffect } from 'react';
import Button from '../../../components/Button';
import { GreenEllipse, ModalCloseIcon } from '../../../components/icons';
import { ResourcesDetailsType } from '../slice/types';

const FormItem = Form.Item;
interface IProps extends ModalFuncProps {
  form: FormInstance<any>;
  onFinish: ((values: any) => void) | undefined;
  categoryOptions: { value: string | number, label: string; }[];
  gradeOptions: { value: string | number, label: string; }[];
  resourceDetails: ResourcesDetailsType | null;
  loading: boolean;
}
const ResourceAddForm = (props: IProps) => {
  const { open, onCancel, onFinish, form, categoryOptions, gradeOptions, resourceDetails, loading } = props;
  useEffect(() => {
    form.setFieldValue('category', resourceDetails?.category);
    form.setFieldValue('grade', resourceDetails?.grade);
    form.setFieldValue('name', resourceDetails?.name);
    form.setFieldValue('link', resourceDetails?.link);
    form.setFieldValue('description', resourceDetails?.description);
  }, []);

  return (
    <Modal
      open={open}
      title={<><GreenEllipse />  {resourceDetails ? resourceDetails?.name : "Add Resources"} </>}
      onCancel={onCancel}
      closeIcon={<ModalCloseIcon />}
      footer={[
        <Button label={resourceDetails ? "Update" : "Add"} variation="action" onClick={() => form.submit()} />,
      ]}
      className="custom-modal"
      destroyOnClose={true}
      {...props}
    >
      <Spin spinning={loading}>
        <Form
          preserve={false}
          layout="vertical"
          form={form}
          onFinish={onFinish}
          className="custom-add-form"
          initialValues={resourceDetails ? { ...resourceDetails } : {}}
        >
          <Row gutter={20}>
            <Col span={12}>
              <FormItem
                label="Category"
                name="category"
                rules={[{ required: true, message: 'Please select the category!' }]}>
                <Select
                  // defaultValue={categoryOptions[0]?.value}
                  options={categoryOptions}
                  className="custom-select"
                  placeholder="Select Categroy"
                />
              </FormItem>
            </Col>
            <Col span={12}>
              <FormItem
                label="Grade"
                name="grade"
                rules={[{ required: true, message: 'Please select the grade!' }]}>
                <Select
                  options={gradeOptions}
                  placeholder="Select Grade"
                  className="custom-select"
                />
              </FormItem>
            </Col>
          </Row>

          <Row gutter={20}>
            <Col span={12}>
              <FormItem
                label="Title"
                name="name"
                rules={[{ required: true, message: 'Please enter the title!' }]}>
                <Input
                  placeholder="Write video title"
                />
              </FormItem>
            </Col>
            <Col span={12}>
              <FormItem
                label="Link"
                name="link"
                rules={[
                  {
                    required: true,
                    message: 'Please enter the link!'
                  },
                  {
                    type: "url",
                    message: "This field must be a valid url."
                  }]}>
                <Input
                  placeholder="https://www.youtube.com/hedsdac"
                />
              </FormItem>
            </Col>
          </Row>

          <Row gutter={20}>
            <Col span={24}>
              <FormItem
                label="Explanation"
                name="description"
                rules={[{ required: true, message: 'Please enter the title!' }]}>
                <Input.TextArea
                  placeholder="Write video explanation"
                  rows={6}
                  style={{ resize: 'none' }}
                />
              </FormItem>
            </Col>
          </Row>
        </Form>
      </Spin>
    </Modal>
  );
};
export default ResourceAddForm;

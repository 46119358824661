import { createSelector } from '@reduxjs/toolkit';

import { RootState } from 'types';
import { initialState } from '.';

const selectSlice = (state: RootState) => state?.login || initialState;

export const selectLogin = createSelector(
  [selectSlice],
  state => state,
);

export const selectLoading = createSelector(
  [selectSlice],
  state => state.loading,
);

export const selectLoginError = createSelector(
  [selectSlice],
  state => state.error,
);

export const selectLoginSuccess = createSelector(
  [selectSlice],
  state => state.success,
);
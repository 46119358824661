/**
*
* StudentsDetails
*
*/
import { memo, useState } from 'react';
import { Spin } from 'antd';
import { EditIcon, GreenEllipse } from '../../../components/icons';
import { ResourcesDetailsType } from '../slice/types';
import ReactPlayer from 'react-player/lazy';
import LinkIcon from '../../../components/icons/LinkIcon';
import "../styles.scss";
import { PlayCircleFilled } from '@ant-design/icons';
import AnchorLink from 'antd/es/anchor/AnchorLink';

interface Props {
  resourceDetails: ResourcesDetailsType;
  handleEdit: (data: ResourcesDetailsType) => void;
  loading: boolean;
}

export const ResourcesDetails = memo((props: Props) => {
  const { resourceDetails, handleEdit, loading } = props;

  return (
    <Spin spinning={loading}>
      <div className='right-sidebar-container' style={{ display: 'block' }}>
        <div className='flex flex--align-center'>
          <div>
            <GreenEllipse />
          </div>
          <span style={{
            fontWeight: '500',
            fontSize: '20px',
            lineHeight: '23px',
            color: '#FFFFFF',
            marginRight: '25px'
          }}>{resourceDetails.name}</span></div>
        <div className='border-bottom'></div>
        <div>
          <div className="player-wrapper">
            {ReactPlayer.canPlay(resourceDetails?.link) && <ReactPlayer
              url={resourceDetails.link}
              className="react-player"
              width="100%"
              height="100%"
              controls={true}
              playIcon={<PlayCircleFilled />}
              config={{
                file: {
                  attributes: {
                    crossOrigin: 'anonymous',
                    preload: 'none',
                  },
                },
              }}
            />}
          </div>
          <div className='resource-description'>
            {resourceDetails.description}
          </div>
        </div>
        <div className='details-link flex flex--justify-between flex--align-center'
          style={{ columnGap: '4px' }}>
          <AnchorLink href={resourceDetails.link} title={resourceDetails.link} target="_blank" style={{ color: '#000000' }} />
          <div>
            <LinkIcon />
          </div>
        </div>
        <div className='right-sidebar-container-edit-icon' onClick={() => handleEdit(resourceDetails)}><EditIcon /></div>
      </div>
    </Spin >
  );
});


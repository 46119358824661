
import { useHistory } from 'react-router-dom';
import "./styles.scss";
interface IProps {
  icon: React.ReactNode;
  count: number;
  label: string;
}
const CountCard = (props: IProps) => {
  const { icon, count = 0, label } = props;
  const history = useHistory();
  return (
    <div className="counter-main-container" onClick={() => history.push(`/${label.toLowerCase()}`)}>
      <div className="couner-sub-section">
        <div className="counter-icon">
          {icon}
        </div>
        <div className="counter-details">
          <span className="counter-details-count">{count}</span>
          <span className="counter-details-label">{label}</span>
        </div>
      </div>
    </div>
  );
};

export default CountCard;
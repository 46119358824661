import { message, Modal as AntModal } from 'antd';
import Button from '../Button';
import { ModalCloseIcon } from '../icons';

type Props = {
  inputProps?: {};
  isModalVisible: boolean;
  handleOk: (val: any) => void;
  handleCancel: () => void;
  message: string;
  title: React.ReactNode | string;
  btnTitle: string;
};
export default function Modal(props: Props) {
  const { isModalVisible, handleOk, handleCancel, title, message, btnTitle } = props;
  return (
    <AntModal
      title={title}
      open={isModalVisible}
      centered
      onOk={handleOk}
      onCancel={handleCancel}
      closeIcon={<ModalCloseIcon />}
      footer={[
        <Button label={btnTitle} variation="delete" onClick={handleOk} />,
      ]}
    >
      {message}
    </AntModal>
  );
}

import { Image, Row, Col } from 'antd';
import { getFullName } from "../../../utils";
import { Form, FormInstance, Input } from 'antd';
import EnvelopeIcon from "../icons/EnvelopeIcon";
import PhoneIcon from "../icons/PhoneIcon";
// import DefaultImage from "assets/icons/default-image.svg";
import { UserInformation } from '../../modules/Dashboard/slice/types';
import { useEffect, useRef, useState } from 'react';
import CameraIcon from '../icons/CameraIcon';
import { phoneValidation } from '../../../utils/commonMethods';
import DefaultImage from '../icons/DefaultImage';
const FormItem = Form.Item;
interface IProps {
  userDetails: UserInformation;
  form?: FormInstance<any>;
  onFinish?: ((values: any, imgFile?: any) => void);
  isEdit?: boolean;
}
const UserDetailsTopSection = (props: IProps) => {
  const { userDetails, form, onFinish, isEdit = false } = props;
  const inputFile = useRef(null);
  const [imgInfo, setImgInfo] = useState<any>({ file: null, url: null });
  const uploadProfileImage = (e) => {
    const imageFile = e.target.files[0];
    if (imageFile) {
      setImgInfo({ file: imageFile, url: URL.createObjectURL(imageFile) });
    }
  };
  useEffect(() => {
    if (!isEdit) setImgInfo({ file: null, url: null });
    if (form) {
      form.setFieldValue('phone_number', userDetails?.phone_number);
      form.setFieldValue('email', userDetails?.email);
      form.setFieldValue('name', userDetails?.name);
    }
  }, [isEdit]);
  return (
    <div className='flex flex--align-center' style={{ columnGap: '16px' }}>
      <div style={{ position: 'relative' }}>
        {/* <Image
          src={imgInfo?.url || userDetails?.profile_image || DefaultImage}
          alt={userDetails.name}
          height={68}
          width={68}
          style={{ borderRadius: '50%', padding: '3px', background: '#7E8E51' }}
          preview={false}
        /> */}

        {imgInfo?.url || userDetails?.profile_image ?
          <Image
            src={imgInfo?.url || userDetails?.profile_image}
            alt={userDetails.name}
            height={68}
            width={68}
            style={{ borderRadius: '50%' }}
            preview={false}
          />
          :
          <DefaultImage width={68} height={68} />
        }
        {isEdit &&
          <div onClick={() => { if (inputFile) inputFile.current.click(); }} className='camera-icon'>
            <CameraIcon />
          </div>
        }
        <input
          type="file"
          onChange={uploadProfileImage}
          ref={inputFile}
          className="d-none"
        />
      </div>
      <div>
        <Form
          preserve={false}
          layout="vertical"
          form={form}
          onFinish={onFinish ? (values) => onFinish(values, imgInfo?.file) : undefined}
          className="custom-update-form"
          key={"form"}
          initialValues={{ ...userDetails }}
        >
          {
            !isEdit &&
            <p className='screen-main-title' style={{ color: '#fff', marginRight: '24px' }}>{getFullName(userDetails)}</p>
          }
          {
            isEdit &&
            <FormItem name={"name"} rules={[{ required: true }]} style={{ marginBottom: '4px' }}>
              <Input />
            </FormItem>
          }
          <div className='flex flex--justify-between flex--wrap-wrap' style={{ columnGap: '10px' }}>
            {
              userDetails?.phone_number && !isEdit &&
              <div className='right-sidebar-container--subtitle flex'><PhoneIcon /> {userDetails?.phone_number}</div>
            }
            {
              userDetails?.email && !isEdit &&
              <div className='right-sidebar-container--subtitle flex'><EnvelopeIcon />{userDetails?.email}</div>
            }
            {
              isEdit &&
              <Row gutter={4}>
                <Col span={10}>
                  <FormItem
                    name={"phone_number"}
                    rules={[
                      {
                        validator(_, value) {
                          return phoneValidation(value);
                        },
                      },
                    ]}
                    style={{ marginBottom: '4px' }}>
                    <Input placeholder='+1XXXXXXXXXX' />
                  </FormItem>
                </Col>
                <Col span={14}>
                  <FormItem
                    name={"email"}
                    rules={[
                      { required: true },
                      {
                        type: 'email',
                        message: 'Invalid email!',
                      }
                    ]}
                    style={{ marginBottom: '4px' }}>
                    <Input placeholder='something@xyz.com' />
                  </FormItem>
                </Col>
              </Row>
            }
          </div>
          {/* {
            userDetails?.account_type === 'teacher' &&
            <div className='right-sidebar-container--title' style={{ marginTop: '10px' }}>
              Grade: {userDetails?.grade_detail?.name}
            </div>
          } */}
        </Form>
      </div>
    </div >
  );
};

export default UserDetailsTopSection;
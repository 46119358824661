import { AxiosRequestConfig } from 'axios';
import { call, put, takeLatest, delay } from 'redux-saga/effects';
import { studentsActions as actions } from '.';
import request from '../../../../utils/request';
import { layoutActions } from '../../Layout/slice';

function* fetchStudentsResourceList({ payload }) {
  yield delay(500);
  yield put(layoutActions.setLoading(true));
  const { id } = payload;
  let url = `/students/${id}/resources/`;
  const options: AxiosRequestConfig = {
    method: 'GET',
  };
  try {
    const response = yield call(
      request,
      url,
      options,
    );

    yield put(actions.setStudentsResourceList(response));
    yield put(layoutActions.setLoading(false));
  } catch (ex: any) {
    yield put(layoutActions.setError(ex.message));
    yield put(layoutActions.setLoading(false));
  }
}

function* assignResourceSubmit({ payload }) {
  yield delay(500);
  const { data, student } = payload;
  yield put(layoutActions.setLoading(true));
  let url = `/students/assign-resource/`;
  const options: AxiosRequestConfig = {
    method: 'POST',
    data: data
  };
  try {
    yield call(request, url, options);
    yield put(actions.getStudentsResourceList({
      id: student
    }));
    yield put(layoutActions.setLoading(false));
    yield put(actions.setIsAssignResource(true));
    yield put(layoutActions.setSuccess("Resource assigned successfully"));
  } catch (ex: any) {
    yield put(layoutActions.setError(ex.message));
    yield put(layoutActions.setLoading(false));
  }
}

function* deleteAssignedResource({ payload }) {
  yield delay(500);
  yield put(layoutActions.setLoading(true));
  const { data, studentId } = payload;
  let url = `/students/assign-resource/`;
  const options: AxiosRequestConfig = {
    method: 'DELETE',
    data: data
  };
  try {
    yield call(request, url, options);
    yield put(actions.getStudentsResourceList({
      id: studentId
    }));
    yield put(layoutActions.setLoading(false));
    yield put(layoutActions.setSuccess("Resource removed successfully"));
  } catch (ex: any) {
    yield put(layoutActions.setError(ex.message));
    yield put(layoutActions.setLoading(false));
  }
}

export function* studentsSaga() {
  yield takeLatest(actions.getStudentsResourceList, fetchStudentsResourceList);
  yield takeLatest(actions.assignResourceSubmit, assignResourceSubmit);
  yield takeLatest(actions.deleteAssignedResource, deleteAssignedResource);
}

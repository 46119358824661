/**
*
* Resources
*
*/
import { Col, Form, Row, Table } from 'antd';
import React, { memo, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Button from '../../components/Button';
import PageTitle from '../../components/Header/PageTitle';
import SearchInput from '../../components/Header/SearchInput';
import { GreenEllipse, PlusIcon } from '../../components/icons';
import DropdownIcon from '../../components/icons/DropdownIcon';
import Modal from '../../components/Modal';
import { useDashboardSlice } from '../Dashboard/slice';
import { selectDashboard } from '../Dashboard/slice/selectors';
import { Layout } from '../Layout';
import { selectLayout } from '../Layout/slice/selectors';
import ResourceAddForm from './components/ResourceAddForm';
import { ResourcesDetails } from './components/ResourceDetails';
import { useResourcesSlice } from './slice';
import { selectResources } from './slice/selectors';
import { ResourcesDetailsType } from './slice/types';

interface Props { }

export const Resources = memo((props: Props) => {
  const dispatch = useDispatch();
  const { resourcesList, isAddedResources } = useSelector(selectResources);
  const { loading } = useSelector(selectLayout);
  const { schoolClassList, categoryList } = useSelector(selectDashboard);
  const { actions } = useResourcesSlice();
  const dashboardActions = useDashboardSlice();
  const [search, setSearch] = useState('');
  const [page, setPage] = useState(1);
  const [pageSize] = useState(20);
  const [selectedResources, setSelectedResources] = useState<ResourcesDetailsType | null>(null);
  const [openFormModal, setOpenFormModal] = useState<{
    isVisible: boolean;
    selectedResource: ResourcesDetailsType | null;
  }>({
    isVisible: false,
    selectedResource: null
  });
  const [modalDetails, setModalDetails] = useState({
    modalId: null,
    isVisible: false
  });
  const [filters, setFilters] = useState<any>({
    categories: null,
    grades: null,
  });
  const [form] = Form.useForm();
  const handleSearch = (val: React.SetStateAction<string>) => {
    setSearch(val);
  };
  const handleResourceDelete = () => {
    if (modalDetails?.modalId)
      dispatch(actions.deleteResourceById({ id: modalDetails.modalId }));
    setModalDetails({
      modalId: null,
      isVisible: false
    });
  };
  const handleEdit = (data: ResourcesDetailsType | null) => {
    setOpenFormModal({
      isVisible: true,
      selectedResource: data
    });
  };
  const handleCancel = () => {
    setOpenFormModal({
      isVisible: false,
      selectedResource: null
    });
    form.setFieldsValue({});
    // form.resetFields();
  };
  const handleAddResource = (values: any) => {
    if (openFormModal.selectedResource)
      dispatch(actions.updateResourceById({ id: openFormModal.selectedResource?.id, data: values }));
    else
      dispatch(actions.addNewResource({ data: values }));
  };

  // UseEffects
  useEffect(() => {
    dispatch(actions.getResourcesList({
      search: search, page: page, pageSize: pageSize, filters: filters
    }));
    dispatch(dashboardActions.actions.getSchoolClassList());
    dispatch(dashboardActions.actions.getCategoryList({ search: '' }));
  }, []);
  useEffect(() => {
    dispatch(actions.getResourcesList({
      search: search, page: page, pageSize: pageSize, filters: filters
    }));
  }, [search, page, filters]);
  useEffect(() => {
    if (resourcesList?.results) {
      setSelectedResources(resourcesList?.results[0]);
    }
  }, [resourcesList]);
  useEffect(() => {
    if (isAddedResources) {
      setOpenFormModal({
        isVisible: false,
        selectedResource: null
      });
      form.resetFields();
      dispatch(actions.setAddedResource(null));
    }
  }, [isAddedResources]);

  const columns: any = [
    {
      title: '',
      dataIndex: 'id',
      width: '1%',
      render: (_, row) => (<GreenEllipse fill={selectedResources?.id === row.id ? '#424242' : '#D9D9D9'} />)
    },
    {
      title: 'Title',
      dataIndex: 'name',
      width: '30%',
    },
    {
      title: 'Category',
      dataIndex: 'category',
      width: '30%',
      render: (_, row) => (
        <div>{categoryList?.results?.find(item => item.id === row.category)?.name}</div>
      ),
      filterIcon: () => <DropdownIcon />,
      filters: categoryList?.results?.map(item => { return { text: item.name, value: item.id }; }),
    },
    {
      title: 'Grade',
      dataIndex: 'grade',
      width: '20%',
      render: (_, row) => (
        <div>{schoolClassList?.results?.find(item => item.id === row.grade)?.name}</div>
      ),
      filterIcon: () => <DropdownIcon />,
      filters: schoolClassList?.results?.map(item => { return { text: item.name, value: item.id }; })
    },
    {
      title: 'Action',
      render: (_: any, row: any) => {
        return (
          <div className='table-action-btn'>
            <Button
              key={`action-${row.id}`}
              label="Remove"
              variation='secondary'
              onClick={() => setModalDetails({ isVisible: true, modalId: row.id })}
            />
          </div>
        );
      },
      width: '19%',
    },
  ];
  return (
    <Layout>
      <Row className="header-container flex--justify-between" style={{ marginTop: '16px' }}>
        <Col span={6}>
          <PageTitle title={"Resources"} subtitle={''} titleIcon={<GreenEllipse />} />
        </Col>
        <Col span={12}>
          <SearchInput handleSearch={handleSearch} />
        </Col>

        <Col span={5}>
          <Button
            label={<span><PlusIcon color='white' /> Add Resources</span>}
            variation="action"
            style={{ width: '100%', height: '48px', background: '#424242' }}
            onClick={() => {
              setOpenFormModal({
                isVisible: true,
                selectedResource: null
              });
            }}
            disabled={!resourcesList}
          />
        </Col>
      </Row>
      <Row className='students-main-container'>
        <Col span={16}>
          <Table
            loading={loading}
            columns={columns || []}
            dataSource={resourcesList?.results}
            pagination={{
              current: page,
              total: resourcesList?.count || 0,
              onChange(page) {
                setPage(page);
              },
              pageSize: pageSize
            }}
            onChange={(pagination, filter) => {
              setFilters({
                ...filters,
                grades: filter.grade,
                categories: filter.category
              });
            }}
            rowKey={obj => obj.id.toString()}
            onRow={(record) => {
              return {
                onClick: () => {
                  setSelectedResources(record);
                }, // click row
              };
            }}
          />
        </Col>
        <Col span={8} style={{ paddingLeft: '15px' }}>
          {
            selectedResources &&
            <ResourcesDetails
              resourceDetails={selectedResources}
              handleEdit={handleEdit}
              loading={loading}
            />
          }
        </Col>
      </Row>
      {
        openFormModal.isVisible &&
        <ResourceAddForm
          onFinish={handleAddResource}
          open={openFormModal.isVisible}
          form={form}
          onCancel={handleCancel}
          resourceDetails={openFormModal.selectedResource}
          categoryOptions={categoryList?.results?.map(item => { return { value: item.id, label: item.name }; })}
          gradeOptions={schoolClassList?.results?.map(item => { return { value: item.id, label: item.name }; })}
          loading={loading}
        />
      }
      <Modal
        handleCancel={() => setModalDetails({ isVisible: false, modalId: null })}
        handleOk={handleResourceDelete}
        isModalVisible={modalDetails.isVisible}
        message={"Are you sure you want to delete this resource?"}
        title={"Resource Delete"}
        btnTitle={"Delete"}
      />
    </Layout >
  );

});


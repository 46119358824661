/**
*
* Categories
*
*/
import { Col, Form, Row, Table } from 'antd';
import React, { memo, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Button from '../../components/Button';
import PageTitle from '../../components/Header/PageTitle';
import SearchInput from '../../components/Header/SearchInput';
import { GreenEllipse, PlusIcon } from '../../components/icons';
import Modal from '../../components/Modal';
import { dashboardActions, useDashboardSlice } from '../Dashboard/slice';
import { selectDashboard } from '../Dashboard/slice/selectors';
import { Layout } from '../Layout';
import { selectLayout } from '../Layout/slice/selectors';
import CategoryAddForm from './components/Form';
import { useCategoriesSlice } from './slice';

interface Props { }

export const Categories = memo((props: Props) => {
  const dispatch = useDispatch();
  const { loading } = useSelector(selectLayout);
  const { categoryList } = useSelector(selectDashboard);
  const dashboardActions = useDashboardSlice();
  const { actions } = useCategoriesSlice();
  const [search, setSearch] = useState('');
  const [page, setPage] = useState(1);
  const [pageSize] = useState(20);
  const [openFormModal, setOpenFormModal] = useState(false);
  const [form] = Form.useForm();
  const [modalDetails, setModalDetails] = useState({
    modalId: null,
    isVisible: false
  });

  const handleSearch = (val: React.SetStateAction<string>) => {
    setSearch(val);
  };
  const handleCancel = () => {
    setOpenFormModal(false);
  };
  const handleAddCategory = (values: any) => {
    setOpenFormModal(false);
    form.resetFields();
    dispatch(actions.addNewCategories({ data: values }));
  };
  const handleCategoryDelete = () => {
    dispatch(actions.deleteCategoryById({ id: modalDetails.modalId }));
    setModalDetails({
      modalId: null,
      isVisible: false
    });
  };
  // UseEffects
  useEffect(() => {
    dispatch(dashboardActions.actions.getCategoryList({
      search: search, page: page, pageSize: pageSize
    }));
  }, []);
  useEffect(() => {
    dispatch(dashboardActions.actions.getCategoryList({
      search: search, page: page, pageSize: pageSize
    }));
  }, [search, page]);

  const columns = [
    {
      title: 'Title',
      dataIndex: 'name',
      width: '85%',
    },
    {
      title: 'Action',
      render: (_: any, row: any) => {
        return (
          <div className='table-action-btn'>
            <Button
              key={`action-${row.id}`}
              label="Remove"
              variation='secondary'
              onClick={() => setModalDetails({ isVisible: true, modalId: row.id })}
            />
          </div>
        );
      },
      width: '15%',
    },
  ];

  return (
    <Layout>
      <Row className="header-container flex--justify-between" style={{ marginTop: '16px' }}>
        <Col span={6}>
          <PageTitle title={"Categories"} subtitle={''} titleIcon={<GreenEllipse />} />
        </Col>
        <Col span={12}>
          <SearchInput handleSearch={handleSearch} />
        </Col>
        <Col span={5}>
          <Button
            label={<span><PlusIcon color='white' /> Add Category</span>}
            variation="action"
            style={{ width: '100%', height: '48px', background: '#424242' }}
            onClick={() => {
              setOpenFormModal(true);
            }} />
        </Col>
      </Row>
      <Row className='students-main-container'>
        <Col span={24}>
          <Table
            tableLayout='fixed'
            loading={loading}
            columns={columns}
            dataSource={categoryList?.results}
            pagination={{
              current: page,
              total: categoryList?.count || 0,
              onChange(page) {
                setPage(page);
              },
              pageSize: pageSize
            }}
            rowKey={obj => obj.id.toString()}
          />
        </Col>
      </Row>
      <CategoryAddForm onFinish={handleAddCategory} open={openFormModal} form={form} onCancel={handleCancel} />
      <Modal
        handleCancel={() => setModalDetails({ isVisible: false, modalId: null })}
        handleOk={handleCategoryDelete}
        isModalVisible={modalDetails.isVisible}
        message={"Are you sure you want to delete this category?"}
        title={"Category Delete"}
        btnTitle={"Delete"}
      />
    </Layout>
  );

});


import jwtDecode from 'jwt-decode';

export const auth = {
  setLoginData(token: string, user: any, remember): void {
    if (remember) {
      localStorage.setItem('user_token', token);
      localStorage.setItem('user_info', user);
    } else {
      sessionStorage.setItem('user_token', token);
      sessionStorage.setItem('user_info', user);
    }
  },
  getToken(): string | null {
    let token: string | null = '';
    if (localStorage.getItem('user_token')) {
      token = localStorage.getItem('user_token');
    } else if (sessionStorage.getItem('user_token')) {
      token = sessionStorage.getItem('user_token');
    }
    if (token) {
      return token;
    } else {
      // this.logout();
      return null;
    }
  },
  logout() {
    window.localStorage.setItem('CREDENTIALS_FLUSH', Date.now().toString());
    window.localStorage.removeItem('CREDENTIALS_FLUSH');
    localStorage.removeItem('user_token');
    sessionStorage.removeItem('user_token');
    localStorage.removeItem('user_info');
    sessionStorage.removeItem('user_info');
    window.location.href = "/login";
  },
  getUserData(): any {
    let userData: string | null = '';
    if (localStorage.getItem('user_info')) {
      userData = JSON.parse(localStorage.getItem('user_info') || '');
    } else if (sessionStorage.getItem('user_info')) {
      userData = sessionStorage.getItem('user_info');
    }
    if (userData) {
      return userData;
    } else {
      // this.logout();
      return null;
    }
  },
  isLogin(): boolean {
    const token = this.getToken();
    if (token !== null) {
      // @ts-ignore
      // const { exp } = jwtDecode(token);
      // if (Date.now() >= exp * 1000) {
      //   this.logout();
      //   return false;
      // }
      return true;
    }
    return false;
  },
};

/**
*
* Parents
*
*/
import { Col, Form, Image, Row, Table } from 'antd';
import React, { memo, useEffect, useReducer, useState } from 'react';
import Button from '../../components/Button';
import PageTitle from '../../components/Header/PageTitle';
import SearchInput from '../../components/Header/SearchInput';
import { Layout } from '../Layout';
// import DefaultImage from "../../../assets/icons/default-image.svg";
import "./styles.scss";
import { useDispatch, useSelector } from 'react-redux';
import { selectDashboard } from '../Dashboard/slice/selectors';
import { useDashboardSlice } from '../Dashboard/slice';
import DeleteIcon from '../../components/icons/DeleteIcon';
import Modal from '../../components/Modal';
import { ModalTypes } from '../../../constant';
import { UserInformation } from '../Dashboard/slice/types';
import { GreenEllipse, PlusIcon } from '../../components/icons';
import { selectLayout } from '../Layout/slice/selectors';
import { ParentsDetails } from './components/ParentsDetails';
import AddParentForm from './components/AddParentForm';
import { useParentsSlice } from './slice';
import { AddParentRequest } from './slice/types';
import { selectParents } from './slice/selectors';
import ImportCSV from '../../components/ImportCSV';
import BulkImport from '../../components/BulkImport';
import DefaultImage from '../../components/icons/DefaultImage';

interface Props { }

export const Parents = memo((props: Props) => {
  const { parentList } = useSelector(selectDashboard);
  const { isAdded } = useSelector(selectParents);
  const { loading } = useSelector(selectLayout);
  const dispatchRedux = useDispatch();
  const { actions } = useDashboardSlice();
  const parentActions = useParentsSlice();
  const [search, setSearch] = useState('');
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(20);
  const [selectedUser, setSelectedUser] = useState<UserInformation | null>(null);
  const [openFormModal, setOpenFormModal] = useState({
    isModalVisible: false,
    selectedParent: null
  });
  const [form] = Form.useForm();

  const ACTION_TYPES = {
    HANDLE_MODAL: 'HANDLE_MODAL',
  };
  const reducer = (state: any, action: {
    type: any;
    payload: {
      isModalVisible: boolean;
      modalTypes: any;
      modalId: string | null | number | React.Key[];
    };
  }) => {
    switch (action.type) {
      case ACTION_TYPES.HANDLE_MODAL:
        return {
          ...state,
          isModalVisible: action.payload.isModalVisible,
          modalTypes: action.payload.modalTypes,
          modalId: action.payload.modalId
        };
      default:
        return state;
    }
  };
  const [state, dispatch] = useReducer(reducer, {
    isModalVisible: false,
    modalTypes: ModalTypes.activate,
    modalId: null
  });
  const columns = [
    {
      title: 'Image',
      dataIndex: 'profile_image',
      width: '15%',
      render: (_, row) => {
        return (
          row.profile_image ?
            <Image
              src={row.profile_image}
              alt={row.name}
              height={34}
              width={34}
              style={{ borderRadius: '50%' }}
              preview={false}
            />
            :
            <DefaultImage />);
      }
    },
    {
      title: 'Name',
      dataIndex: 'name',
      width: '25%',
      render: (_, row) => (<div>{row?.name || row.username}</div>)
    },
    {
      title: 'Email',
      dataIndex: 'email',
      width: '35%',
    },
    {
      title: 'Action',
      render: (_: any, row: UserInformation) => {
        return (
          <div className='table-action-btn'>
            {
              !row.is_active ? <Button
                key={`activate-${row.id}`}
                variation="primary"
                label={"Activate"}
                onClick={() => {
                  dispatch({
                    type: ACTION_TYPES.HANDLE_MODAL,
                    payload: { isModalVisible: true, modalTypes: ModalTypes.activate, modalId: row.id },
                  });
                }
                }
              />
                :
                <Button
                  key={`action-${row.id}`}
                  label="Deactivate"
                  variation='secondary'
                  onClick={() => {
                    dispatch({
                      type: ACTION_TYPES.HANDLE_MODAL,
                      payload: { isModalVisible: true, modalTypes: ModalTypes.deactivate, modalId: row.id },
                    });
                  }
                  }
                />
            }
            <div
              className='search-icon' onClick={() => {
                handleDelete(row.id);
              }}>
              <DeleteIcon />
            </div>
          </div>
        );
      },
      width: '25%',
    },
  ];
  useEffect(() => {
    dispatchRedux(actions.getUserList({
      search: search, page: page, pageSize: pageSize, account_type: 'parent'
    }));
    dispatchRedux(actions.getSchoolClassList());
  }, []);
  useEffect(() => {
    dispatchRedux(actions.getUserList({
      search: search, page: page, pageSize: pageSize, account_type: 'parent'
    }));
  }, [search, page, pageSize]);
  useEffect(() => {
    if (parentList.results) {
      setSelectedUser(parentList?.results[0]);
    }
  }, [parentList]);
  useEffect(() => {
    if (isAdded) {
      setOpenFormModal({
        isModalVisible: false,
        selectedParent: null
      });
      form.resetFields();
      dispatchRedux(parentActions.actions.setIsAdded(null));
    }
  }, [isAdded]);

  const handleSearch = (val: React.SetStateAction<string>) => {
    setSearch(val);
  };
  const handleDelete = (id) => {
    dispatch({
      type: ACTION_TYPES.HANDLE_MODAL,
      payload: { isModalVisible: true, modalTypes: ModalTypes.remove, modalId: id },
    });
  };
  const handleCancel = () => {
    dispatch({
      type: ACTION_TYPES.HANDLE_MODAL,
      payload: { isModalVisible: false, modalTypes: ModalTypes.activate, modalId: null },
    });
  };
  const handleOk = (status: 'activate' | 'deactivate' | 'remove' | 'remove all') => {
    if (status === 'remove')
      dispatchRedux(actions.deleteUserInformation({ username: state.modalId, account_type: 'parent' }));
    else if (status === 'remove all') {
      dispatchRedux(actions.deleteSelectedUser({ user_list: state.modalId, account_type: 'parent' }));
      setSelectedRowKeys([]);
    }
    else
      dispatchRedux(actions.updateUserStatus({ username: state.modalId, status: status }));
    dispatch({
      type: ACTION_TYPES.HANDLE_MODAL,
      payload: { isModalVisible: false, modalTypes: ModalTypes.activate, modalId: null },
    });
  };
  const handleAddParent = (values: AddParentRequest, parentId) => {
    if (!parentId) {
      values.account_type = 'parent';
      dispatchRedux(parentActions.actions.addParentsSubmit({ data: values }));
    }
    else {
      const childData = values.children;
      delete values.children;
      dispatchRedux(actions.updateUserInformation({ id: parentId, data: values, account_type: 'parent' }));
      dispatchRedux(parentActions.actions.addStudentsToParent({ data: { children: childData, parent: parentId } }));
    }
  };
  // Table Row Selection
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  return (
    <Layout>
      <Row className="header-container flex--justify-between" style={{ marginTop: '16px' }}>
        <Col span={3}>
          <PageTitle title={"Parents"} subtitle={''} titleIcon={<GreenEllipse />} />
        </Col>
        <Col span={8}>
          <SearchInput handleSearch={handleSearch} />
        </Col>
        <Col span={4}>
          {selectedRowKeys.length > 0 ?
            <Button label={<span><DeleteIcon color='white' /> Delete</span>} variation="delete" style={{ width: '100%', height: '48px' }} onClick={() => {
              dispatch({
                type: ACTION_TYPES.HANDLE_MODAL,
                payload: { isModalVisible: true, modalTypes: ModalTypes.removeall, modalId: selectedRowKeys },
              });
            }} /> :
            <Button
              label={<span><PlusIcon /> Add New</span>}
              variation="action"
              style={{ width: '100%', height: '48px', background: '#424242' }}
              onClick={() => setOpenFormModal({ isModalVisible: true, selectedParent: null })}
            />}
        </Col>
        <Col span={4}>
          <ImportCSV account_type={"parent"} />
        </Col>
        <Col span={4}>
          <BulkImport account_type={"parent"} />
        </Col>
      </Row>
      <Row className='students-main-container'>
        <Col span={16}>
          <Table
            columns={columns}
            loading={loading}
            dataSource={parentList?.results}
            pagination={{
              current: page,
              total: parentList?.count,
              onChange(page, pageSize) {
                setPage(page);
                setPageSize(pageSize);
              },
              pageSize: pageSize
            }}
            rowSelection={rowSelection}
            rowKey={obj => obj.id.toString()}
            onRow={(record, rowIndex) => {
              return {
                onClick: () => {
                  setSelectedUser(record);
                }, // click row
              };
            }}
          />
        </Col>
        <Col span={8} style={{ paddingLeft: '15px' }}>
          {selectedUser &&
            <ParentsDetails
              parentDetails={selectedUser}
              handleDelete={handleDelete}
              setOpenFormModal={setOpenFormModal}
            />}
        </Col>
      </Row>
      <Modal
        handleCancel={handleCancel}
        handleOk={() => handleOk(state.modalTypes.title.toLowerCase())}
        isModalVisible={state.isModalVisible}
        message={state.modalTypes.message}
        title={<><GreenEllipse />{state.modalTypes.title}</>}
        btnTitle={state.modalTypes.btnTitle}
      />
      {
        openFormModal.isModalVisible &&
        <AddParentForm
          onFinish={handleAddParent}
          open={openFormModal.isModalVisible}
          form={form}
          onCancel={() => {
            setOpenFormModal({ isModalVisible: false, selectedParent: null });
            form.setFieldsValue({});
          }}
          loading={loading}
          parentDetails={openFormModal.selectedParent}
        />
      }
    </Layout>
  );

});
;

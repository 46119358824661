/**
 *
 * App
 *
 * This component is the skeleton around the actual pages, and should only
 * contain code that should be seen on all pages. (e.g. navigation bar)
 */

import 'antd/dist/reset.css';
import { useEffect } from 'react';
import { Switch } from 'react-router-dom';
import PrivateRoute from './components/PrivateRoute';
import PublicRoute from './components/PublicRoute';
import { Dashboard } from './modules/Dashboard/Loadable';
import { Login } from './modules/Login';
import '../styles/global.scss';
import { menuDetails } from './modules/Layout/constant';

export function App() {
  useEffect(() => {
    window.addEventListener('storage', event => {
      if (event.key === 'CREDENTIALS_FLUSH') {
        window.location.reload();
      }
    });
  }, []);

  return (
    <Switch>
      {
        menuDetails.map(menu =>
        (
          <PrivateRoute exact path={menu.path} component={menu.component} key={menu.path} />
        ))
      }
      <PublicRoute exact path="/login" component={Login} restricted />
    </Switch>
  );
}

/**
*
* Dashboard
*
*/
import { Image, Spin, Table } from 'antd';
import { memo, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Layout } from '../Layout';
import { useDashboardSlice } from './slice';
import { selectDashboard } from './slice/selectors';
// import DefaultImage from "../../../assets/icons/default-image.svg";
import { CategoriesIcon, GreenEllipse, ParentsIcon, StaffsIcon, StudentsIcon } from '../../components/icons';
import CountCard from '../../components/CountCard';
import Header from '../../components/Header';
import { auth } from '../../../utils/auth';
import { formatDate } from '../../../utils/commonMethods';
import "./styles.scss";
import { selectLayout } from '../Layout/slice/selectors';
import DefaultImage from '../../components/icons/DefaultImage';

interface Props { }

export const Dashboard = memo((props: Props) => {
  const { userList, categoryList, userCounts } = useSelector(selectDashboard);
  const { loading } = useSelector(selectLayout);
  const profile = auth.getUserData();
  const [search, setSearch] = useState('');
  const [page] = useState(1);
  const [pageSize] = useState(null);
  const dispatch = useDispatch();
  const { actions } = useDashboardSlice();
  const columns = [
    {
      title: 'Image',
      dataIndex: 'profile_image',
      width: '20%',
      render: (_, row) => {
        return (
          row.profile_image ?
            <Image
              src={row.profile_image}
              alt={row.name}
              height={34}
              width={34}
              style={{ borderRadius: '50%' }}
              preview={false}
            />
            :
            <DefaultImage />);
      }
    },
    {
      title: 'Name',
      dataIndex: 'name',
      width: '50%',
    },
    {
      title: 'Phone Number',
      dataIndex: 'phone_number',
      width: '30%',
    },
    {
      title: '',
      dataIndex: 'action',
      width: '0%',
    },
    // {
    //   title: 'Action',
    //   render: (_, row) => {
    //     return (
    //       <div className='flex' style={{ columnGap: '8px', rowGap: '8px', flexWrap: 'wrap' }}>
    //         <Button
    //           key={`activate-${row.id}`}
    //           variation="primary"
    //           label={"Activate"}
    //           style={{ width: '45%' }}
    //         />
    //         <Button
    //           key={`action-${row.id}`}
    //           label="Deactivate"
    //           variation='secondary'
    //           style={{ width: '45%' }}
    //         />
    //       </div>
    //     );
    //   },
    //   width: '30%',
    // },
  ];

  const handleSearch = (val: string) => {
    setSearch(val);
  };
  useEffect(() => {
    dispatch(actions.getUserList({
      search: search, page: page, pageSize: 10
    }));
    dispatch(actions.getUserCounts());
    dispatch(actions.getCategoryList({ search: '' }));
  }, []);

  useEffect(() => {
    dispatch(actions.getUserList({
      search: search, page: page, pageSize: 10
    }));
  }, [search]);
  return (
    <Layout>
      <Spin spinning={loading}>
        <Header
          title={`${profile.name || profile.username}`}
          subtitle={formatDate(new Date())}
          handleSearch={handleSearch}
        />
        <div className='dashboard-main-container'>
          <div>
            <div className='table-container'>
              <div className='screen-main-title'><GreenEllipse /> Recent Active Users</div>
              <Table
                columns={columns}
                dataSource={userList?.results}
                rowKey={obj => obj.id}
                pagination={false}
              />
            </div>
            {/* <div className='table-container'>
            <div className='screen-main-title'>Recent Parents Deactivate</div>
            <Table columns={columns} dataSource={parentList?.results} pagination={false} rowKey={obj => obj.id} />
          </div>
          <div className='table-container'>
            <div className='screen-main-title'>Recent Teachers Deactivate</div>
            <Table columns={columns} dataSource={teacherList?.results} pagination={false} rowKey={obj => obj.id} />
          </div> */}
          </div>
          <div style={{ width: '100%', columnGap: '20px', margin: '40px 0px' }} className="flex--justify-between flex">
            <CountCard
              icon={<StudentsIcon color='#7E8E51' />}
              label={"Students"}
              count={userCounts.students}
              key="students"
            />
            <CountCard
              icon={<ParentsIcon color='#7E8E51' />}
              label={"Parents"}
              count={userCounts.parents}
              key="parents"
            />
            <CountCard
              icon={<StaffsIcon color='#7E8E51' />}
              label={"Teachers"}
              count={userCounts.teachers}
              key="teachers"
            />
            <CountCard
              icon={<CategoriesIcon color='#7E8E51' />}
              label={"Categories"}
              count={categoryList?.count}
              key="Categories"
            />
          </div>
        </div>
      </Spin>
    </Layout>
  );

});


import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from 'utils/@reduxjs/toolkit';
import { useInjectReducer, useInjectSaga } from 'utils/redux-injectors';
import { loginSaga } from './saga';
import { IFormFields, LoginData, LoginState } from './types';

export const initialState: LoginState = {
  token: null,
  user: null,
  loading: false,
  error: null,
  success: false
};

const slice = createSlice({
  name: 'login',
  initialState,
  reducers: {
    login(state, action: PayloadAction<IFormFields>) { },
    setLogin(state, action: PayloadAction<LoginData>) {
      state.token = action.payload.token;
      state.user = action.payload.user;
    },
    setLoginError(state, action: PayloadAction<string | null>) {
      state.error = action.payload;
    },
    setLoading(state, action: PayloadAction<boolean>) {
      state.loading = action.payload;
    },
    setSuccess(state, action: PayloadAction<boolean>) {
      state.success = action.payload;
    },
  },
});

export const { actions: loginActions } = slice;

export const useLoginSlice = () => {
  useInjectReducer({ key: slice.name, reducer: slice.reducer });
  useInjectSaga({ key: slice.name, saga: loginSaga });
  return { actions: slice.actions };
};

/**
 * Example Usage:
 *
 * export function MyComponentNeedingThisSlice() {
 *  const { actions } = useLoginSlice();
 *
 *  const onButtonClick = (evt) => {
 *    dispatch(actions.someAction());
 *   };
 * }
 */
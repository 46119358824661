
interface IProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  color?: string;
  props?: any;
}
const DeleteIcon = ({ color = "black", props }: IProps) => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M15.3846 2.46074H12.4644C12.0221 2.46074 11.6308 2.17848 11.4913 1.76002L11.232 0.98137C11.0359 0.393883 10.4886 0 9.86994 0H6.12921C5.51054 0 4.96328 0.393864 4.76718 0.982171L4.50791 1.76002C4.36843 2.1793 3.97701 2.46154 3.53476 2.46154H0.615385C0.275692 2.46154 0 2.73723 0 3.07692C0 3.41662 0.275692 3.69231 0.615385 3.69231H1.68039L2.30975 13.1282C2.41723 14.7389 3.76534 16 5.38011 16H10.6207C12.2346 16 13.5836 14.7381 13.6911 13.1282L14.3204 3.69231H15.3846C15.7243 3.69231 16 3.41662 16 3.07692C16 2.73723 15.7243 2.46074 15.3846 2.46074ZM5.93475 1.37029C5.96264 1.2866 6.04059 1.22997 6.12921 1.22997H9.86994C9.95856 1.22997 10.0365 1.28662 10.0644 1.36949L10.3237 2.14814C10.3598 2.25644 10.4049 2.36065 10.4558 2.45994H5.54257C5.59344 2.35983 5.63858 2.25646 5.67468 2.14734L5.93475 1.37029ZM12.4619 13.0454C12.3979 14.0119 11.5889 14.7684 10.6199 14.7684H5.37931C4.4111 14.7684 3.60121 14.0119 3.53721 13.0454L2.91366 3.69151H3.53476C3.62255 3.69151 3.70872 3.68082 3.79487 3.67097C3.82933 3.67672 3.86053 3.69151 3.89663 3.69151H12.1018C12.1379 3.69151 12.169 3.67672 12.2035 3.67097C12.2896 3.68082 12.375 3.69151 12.4636 3.69151H13.0847L12.4619 13.0454ZM10.2564 7.17869V11.2812C10.2564 11.6209 9.98072 11.8966 9.64103 11.8966C9.30133 11.8966 9.02564 11.6209 9.02564 11.2812V7.17869C9.02564 6.83899 9.30133 6.5633 9.64103 6.5633C9.98072 6.5633 10.2564 6.83899 10.2564 7.17869ZM6.97436 7.17869V11.2812C6.97436 11.6209 6.69867 11.8966 6.35897 11.8966C6.01928 11.8966 5.74359 11.6209 5.74359 11.2812V7.17869C5.74359 6.83899 6.01928 6.5633 6.35897 6.5633C6.69867 6.5633 6.97436 6.83899 6.97436 7.17869Z" fill={color} />
  </svg>
);

export default DeleteIcon;
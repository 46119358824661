import { ChangeEvent, useState } from 'react';
import { Input } from 'antd';
import { SearchIcon } from '../icons';

interface Props {
  handleSearch: (val: string) => void;
  style?: React.CSSProperties;
}
export default function SearchInput(props: Props) {
  const { handleSearch, style } = props;
  const [searchText, setSearchText] = useState<string>('');
  return (
    <Input
      autoComplete={'new-password'}
      placeholder={"Search...."}
      suffix={<div className='search-icon'><SearchIcon /></div>}
      className="table-search-input"
      onChange={(e: ChangeEvent<HTMLInputElement>): void => {
        setSearchText(e.target.value.trimStart());
        handleSearch(e.target.value.trimStart());
      }}
      value={searchText}
      allowClear
      style={style}
    />
  );
}

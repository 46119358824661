import { take, call, put, select, takeLatest, delay } from 'redux-saga/effects';
import { staffTeachersActions as actions } from '.';

import { AxiosRequestConfig } from "axios";
import request from "../../../../utils/request";
import { layoutActions } from '../../Layout/slice';

function* fetchTeachersChildren({ payload }) {
  yield delay(500);
  const { id } = payload;
  let url = `/schools/teachers/${id}/children/`;
  const options: AxiosRequestConfig = {
    method: 'GET',
  };
  try {
    const response = yield call(
      request,
      url,
      options,
    );
    yield put(actions.setTeachersChildren(response));
  } catch (ex: any) {
    yield put(layoutActions.setError(ex.message));
  }
}

function* assignStudentSubmit({ payload }) {
  yield delay(500);
  const { data, teacher } = payload;
  yield put(layoutActions.setLoading(true));
  let url = `/teachers/add-students/`;
  const options: AxiosRequestConfig = {
    method: 'POST',
    data: data
  };
  try {
    yield call(request, url, options);
    yield put(actions.getTeachersChildren({
      id: teacher
    }));
    yield put(layoutActions.setLoading(false));
    yield put(actions.setIsAssignStudent(true));
    yield put(layoutActions.setSuccess("Student assigned successfully"));
  } catch (ex: any) {
    yield put(layoutActions.setError(ex.message));
    yield put(layoutActions.setLoading(false));
  }
}

export function* staffTeachersSaga() {
  yield takeLatest(actions.getTeachersChildren, fetchTeachersChildren);
  yield takeLatest(actions.assignStudentSubmit, assignStudentSubmit);
}

/**
*
* StudentsDetails
*
*/
import { Form, Spin } from 'antd';
import { memo, useEffect, useState } from 'react';
import { UserInformation } from '../../../Dashboard/slice/types';
import "../../styles.scss";
import { EditIcon } from '../../../../components/icons';
import { useDispatch, useSelector } from 'react-redux';
import { dashboardActions } from '../../../Dashboard/slice';
import { selectDashboard } from '../../../Dashboard/slice/selectors';
import UserDetailsTopSection from '../../../../components/UserDetailsTopSection';
import Button from '../../../../components/Button';
import { useStudentsSlice } from '../../slice';
import { selectStudents } from '../../slice/selectors';
import ParentInformation from '../ParentInformation';
import ClassInformation from '../ClassInformation';
import LinkIcon from '../../../../components/icons/LinkIcon';
import AnchorLink from 'antd/es/anchor/AnchorLink';
import DeleteIcon from '../../../../components/icons/DeleteIcon';

interface Props {
  studentDetails: UserInformation;
  handleDelete: (id: any, type?: string | null) => void;
  gradeOptions: { value: string | number, label: string; }[];
  setOpenFormModal: any;
}

export const StudentsDetails = memo((props: Props) => {
  const { studentDetails, handleDelete, gradeOptions, setOpenFormModal } = props;
  const [loading, setLoading] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const { actions } = useStudentsSlice();
  const { userInformation, teacherList } = useSelector(selectDashboard);
  const { studentsResourceList } = useSelector(selectStudents);
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const [teacherDetails, setTeacherDetails] = useState<UserInformation | null>(null);

  useEffect(() => {
    setLoading(true);
    if (studentDetails.parent) {
      dispatch(dashboardActions.getUserInformationById({ id: studentDetails.parent }));
    }
    else {
      dispatch(dashboardActions.setUserInformationById(null));
    }
    setIsEdit(false);
    dispatch(actions.getStudentsResourceList({ id: studentDetails?.id }));
    dispatch(dashboardActions.getUserList({
      account_type: 'teacher'
    }));
    dispatch(actions.getStudentsResourceList({ id: studentDetails?.id }));
  }, [studentDetails]);

  useEffect(() => {
    if (userInformation) setLoading(false);
  }, [userInformation]);
  useEffect(() => {
    if (studentsResourceList) setLoading(false);
  }, [studentsResourceList]);

  useEffect(() => {
    dispatch(dashboardActions.getUserList({
      account_type: 'teacher'
    }));
    dispatch(actions.getStudentsResourceList({ id: studentDetails?.id }));
  }, []);

  useEffect(() => {
    if (teacherList && studentDetails?.teacher) {
      setTeacherDetails(teacherList.results?.find(item => item.id === studentDetails?.teacher));
    }
  }, [teacherList]);

  const handleSaveInfo = (values, imageFile) => {
    setLoading(true);
    if (imageFile) values.profile_image = imageFile;
    const updatedParentInfo = values?.parent_details;
    delete values.parent_details;
    dispatch(dashboardActions.updateUserInformation({
      id: studentDetails.id,
      data: values,
      account_type: studentDetails.account_type
    }));
    if (studentDetails?.parent) {
      setTimeout(() => {
        dispatch(dashboardActions.updateUserInformation({
          id: userInformation?.id,
          data: updatedParentInfo,
          account_type: studentDetails.account_type
        }));
      }, 500);
    }
    setIsEdit(false);
    form.resetFields();
  };
  return (
    <Spin spinning={loading}>
      <div className='right-sidebar-container'>
        <div style={{ width: '100%' }}>
          <UserDetailsTopSection userDetails={studentDetails} form={form} isEdit={isEdit} onFinish={handleSaveInfo} />
          <div className='border-bottom'></div>
          {studentDetails.parent && <>
            <Form form={form} initialValues={{ ...studentDetails }} onFinish={handleSaveInfo}>
              <ParentInformation
                parentDetails={userInformation}
                key="parent-info"
                isEdit={isEdit}
                form={form}
              />
            </Form>
            <div className='border-bottom'></div>
          </>
          }
          <Form form={form} initialValues={{ ...studentDetails }} onFinish={handleSaveInfo}>
            <ClassInformation
              gradeOptions={gradeOptions}
              isEdit={isEdit}
              studentDetails={studentDetails}
              teacherDetails={teacherDetails}
              teacherList={teacherList}
              form={form}
            />
          </Form>
          <div className='border-bottom'></div>
          <div>
            <span className='right-sidebar-container--title'>Resources:</span>
            <div className='flex flex--direction-column'>
              {
                studentsResourceList?.results.map(item =>
                  <div
                    className='right-sidebar-container--subtitle flex flex--align-center resource-container flex--justify-between'
                    style={{
                      fontSize: '14px',
                      lineHeight: '16px',
                      color: '#000'
                    }}
                  >
                    <AnchorLink href={item.resource.link} title={item.resource.name} target="_blank" />
                    {isEdit ? <div onClick={() => handleDelete(item.resource.id, 'resource')} style={{ cursor: 'pointer' }}><DeleteIcon /></div> : <LinkIcon color='#000' />}
                  </div>
                )
              }
            </div>
          </div>
          {!isEdit &&
            <div className='right-sidebar-container-edit-icon' onClick={() => setIsEdit(true)}><EditIcon /></div>
          }
          {isEdit && <p className='add-new-btn' onClick={() => setOpenFormModal(true)}>+ Assign a Resource</p>}
        </div>
        {isEdit ? (
          <div className='flex' style={{ columnGap: '4px', width: '100%' }}>
            <Button
              label={<span>Save</span>}
              variation="action"
              style={{ width: '100%', height: '48px' }}
              onClick={() => form.submit()}
            />
            <Button
              label={<span>Cancel</span>}
              variation="action"
              style={{ width: '100%', height: '48px', background: '#6B7579', border: '1px solid white' }}
              onClick={() => setIsEdit(false)}
            />
          </div>
        )
          :
          <div>
            <Button label={<span> Delete</span>} variation="delete" style={{ height: '48px', marginTop: '16px' }}
              onClick={() => { handleDelete(studentDetails.id); }}
            />
          </div>
        }
      </div>
    </Spin>
  );
});


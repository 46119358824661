import axios, { AxiosRequestConfig } from 'axios';
import { API_URL } from 'settings';
import { auth } from './auth';
import parseDjangoError from './parseDjangoError';

class ResponseError extends Error {
  public data: { status: string | number; message: string; };
  constructor(
    message: string,
    data: { status: string | number; message: string; },
  ) {
    super(message);
    this.name = '';
    this.data = data;
  }
}

/**
 * Parses the JSON returned by a network request
 *
 * @param  {object} response A response from a network request
 *
 * @return {object}          The parsed JSON from the request
 */
function parseJSON(response) {
  if (response.status >= 200 && response.status <= 299) {
    return response.data;
  } else {
    throw new ResponseError(
      response.data ? parseDjangoError(response.data) : 'Something went wrong.',
      response.data,
    );
  }
}

/**
 * Checks if a network request came back fine, and throws an error if not
 *
 * @param  {object} response   A response from a network request
 *
 * @return {object|undefined} Returns either the response, or throws an error
 */
function handleError(response) {
  const error = response;
  throw new ResponseError('', {
    status: error,
    message: 'apiErrorMessage',
  });
}
/**
 * Format query params
 *
 * @param params
 * @returns {string}
 */

const customRequest = axios.create({
  baseURL: API_URL,
  validateStatus: function (status) {
    return !!(
      (status >= 200 && status < 300) ||
      status === 422 ||
      status === 500 ||
      400
    );
  },
});

/**
 * Requests a URL, returning a promise
 *
 * @param  {string} url       The URL we want to request
 * @param  {object} [options] The options we want to pass to "fetch"
 *
 * @return {object}           The response data
 */
export default function request(
  url: string,
  requestConfig: AxiosRequestConfig = {},
) {
  const token = auth.getToken();
  const headers = {
    'Content-Type': 'application/json',
    Accept: 'application/json',
  };

  if (token) {
    headers['Authorization'] = 'Token ' + token;
  }

  requestConfig.headers = {
    ...requestConfig.headers,
    ...headers,
  };

  return customRequest(url, requestConfig).then(parseJSON);
}

import { Form, Select } from "antd";
import { useEffect } from "react";
const FormItem = Form.Item;
const ClassInformation = ({ gradeOptions, teacherDetails, teacherList, isEdit, studentDetails, form }) => {
  useEffect(() => {
    form.setFieldValue('grade', studentDetails?.grade);
    form.setFieldValue('teacher', studentDetails?.teacher);
  }, [studentDetails]);

  return (<div>
    <span className='right-sidebar-container--title'>Class Information:</span>
    <div className='flex flex--direction-column' style={{ padding: '15px 0px 0px 0px' }}>
      {
        !isEdit ?
          <div style={{ padding: '0px 15px' }}>
            <div
              className='right-sidebar-container--subtitle flex flex--align-center'
              style={{
                fontSize: '14px',
                lineHeight: '16px'
              }}
            >
              <span className='right-sidebar-container--title'>Grade:</span> {studentDetails?.grade_detail?.name}
            </div>
            <div
              className='right-sidebar-container--subtitle flex flex--align-center'
              style={{
                fontSize: '14px',
                lineHeight: '16px'
              }}
            >
              <span className='right-sidebar-container--title'>Teacher Name:</span> {teacherDetails?.name}
            </div>
          </div> :
          <>
            <FormItem name="grade" style={{ marginBottom: '5px' }} rules={[{ required: true }]}>
              <Select
                options={gradeOptions}
                className="custom-mini-select"
                placeholder="Select Grades"
              />
            </FormItem>
            <FormItem name="teacher" style={{ marginBottom: '5px' }} rules={[{ required: true }]}>
              <Select
                options={teacherList?.results?.map(item => { return { value: item.id, label: item.name }; })}
                className="custom-mini-select"
                placeholder="Select Teachers"
              />
            </FormItem>
          </>
      }

    </div>
  </div>);
};

export default ClassInformation;
import { call, delay, put, takeLatest } from 'redux-saga/effects';
import { AxiosRequestConfig } from 'axios';
import { loginActions as actions } from '.';
import { auth } from 'utils/auth';
import request from 'utils/request';

function* login({ payload }) {
  yield delay(500);
  yield put(actions.setLoading(true));
  const data = {
    username: payload.username,
    password: payload.password,
  };
  const url = `users/login/`;

  const options: AxiosRequestConfig = {
    method: 'POST',
    data,
    headers: {
      'Content-Type': 'application/json',
    },
  };
  try {
    const response = yield call(request, url, options);
    // auth.logout();
    if (response.user.account_type === "admin") {
      auth.setLoginData(response.token, JSON.stringify(response.user), payload.remember);
      // yield put(actions.setLogin({ token: response.token, user: JSON.stringify(response.user) }));
      yield put(actions.setLoading(false));
      yield put(actions.setSuccess(true));
    }
    else {
      yield put(actions.setLoading(false));
      yield put(actions.setLoginError("You're not authorized to access here"));
    }
  } catch (ex: any) {
    // auth.logout();
    yield put(actions.setLoginError(ex.message));
    yield put(actions.setLoading(false));
  }

}

export function* loginSaga() {
  yield takeLatest(actions.login, login);
}

/**
*
* ReplyMessage
*
*/
import { Button, Form, Input } from 'antd';
import React, { memo } from 'react';

interface Props {
  handleReplyMessage: ((values: any, form: any) => void);
  id: number | string;
}

export const ReplyMessage = memo((props: Props) => {
  const [form] = Form.useForm();
  return (
    <Form
      form={form}
      layout="vertical"
      name="normal_login"
      onFinish={(values) => props.handleReplyMessage(values, form)}
      initialValues={{ id: props.id }}
    >
      <Form.Item name="message" label="Message*" rules={[{ required: true }]}>
        <Input.TextArea placeholder='enter message' rows={6} />
      </Form.Item>
      <Form.Item name="id" hidden>
        <Input value={props.id} />
      </Form.Item>
      <Form.Item style={{ textAlign: 'center' }}>
        <Button type="primary" htmlType="submit" className='btn-action'>
          Send
        </Button>
      </Form.Item>
    </Form >
  );

});


export const ModalTypes = {
  remove: {
    title: 'Remove',
    class: 'action',
    btnTitle: 'Remove',
    message: 'Are you sure you want to remove this?'
  },
  resource: {
    title: 'Remove Resource',
    class: 'action',
    btnTitle: 'Remove',
    message: 'Are you sure you want to remove this resource?'
  },
  removeall: {
    title: 'Remove All',
    class: 'action',
    btnTitle: 'Remove All',
    message: 'Are you sure you want to remove all of these?'
  },
  deactivate: {
    title: 'Deactivate',
    class: 'action',
    btnTitle: 'Deactivate',
    message: 'Are you sure you want to deactivate this?'
  },
  activate: {
    title: 'Activate',
    class: 'action',
    btnTitle: 'Activate',
    message: 'Are you sure you want to activate this?'
  }
};
export const EmailIcon = () => (<svg width="16" height="13" viewBox="0 0 16 13" fill="none" xmlns="http://www.w3.org/2000/svg" >
  <path d="M12.1026 0H3.89744C1.74851 0 0 1.74851 0 3.89744V8.82051C0 10.9694 1.74851 12.7179 3.89744 12.7179H12.1026C14.2515 12.7179 16 10.9694 16 8.82051V3.89744C16 1.74851 14.2515 0 12.1026 0ZM3.89744 1.23077H12.1026C13.2135 1.23077 14.1661 1.91503 14.5666 2.88241L9.47937 6.27444C8.5809 6.87259 7.41908 6.87259 6.52143 6.27444L1.43424 2.88241C1.83383 1.91421 2.78646 1.23077 3.89744 1.23077ZM12.1026 11.4872H3.89744C2.42708 11.4872 1.23077 10.2909 1.23077 8.82051V4.22646L5.83794 7.29848C6.49435 7.73581 7.24677 7.95483 8 7.95483C8.75241 7.95483 9.50483 7.73581 10.1621 7.29848L14.7692 4.22646V8.82051C14.7692 10.2909 13.5729 11.4872 12.1026 11.4872Z" fill="#384E48" />
</svg>
);

export const LockIcon = () => (<svg width="14" height="18" viewBox="0 0 14 18" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M11.2903 6.6324V4.29032C11.2903 1.92477 9.36555 0 7 0C4.63445 0 2.70968 1.92477 2.70968 4.29032V6.63329C1.12542 7.26464 0 8.80645 0 10.6129V13.3226C0 15.6881 1.92477 17.6129 4.29032 17.6129H9.70968C12.0752 17.6129 14 15.6881 14 13.3226V10.6129C14 8.80645 12.8746 7.26376 11.2903 6.6324ZM4.06452 4.29032C4.06452 2.67174 5.38142 1.35484 7 1.35484C8.61858 1.35484 9.93548 2.67174 9.93548 4.29032V6.34518C9.85871 6.34067 9.78736 6.32258 9.70968 6.32258H4.29032C4.21265 6.32258 4.14129 6.34157 4.06452 6.34518V4.29032ZM12.6452 13.3226C12.6452 14.9412 11.3283 16.2581 9.70968 16.2581H4.29032C2.67174 16.2581 1.35484 14.9412 1.35484 13.3226V10.6129C1.35484 8.99432 2.67174 7.67742 4.29032 7.67742H9.70968C11.3283 7.67742 12.6452 8.99432 12.6452 10.6129V13.3226ZM7.67742 11.5161V12.4194C7.67742 12.7933 7.37393 13.0968 7 13.0968C6.62606 13.0968 6.32258 12.7933 6.32258 12.4194V11.5161C6.32258 11.1422 6.62606 10.8387 7 10.8387C7.37393 10.8387 7.67742 11.1422 7.67742 11.5161Z" fill="#384E48" />
</svg>
);

export const LogoutIcon = () => (<svg width="12" height="14" viewBox="0 0 12 14" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path opacity="0.7" d="M6.51443 13.1868C6.51443 13.478 6.28402 13.7143 6.00013 13.7143H3.25721C1.46129 13.7143 0 12.2156 0 10.3736V3.34066C0 1.49873 1.46129 0 3.25721 0H6.00013C6.28402 0 6.51443 0.236308 6.51443 0.527473C6.51443 0.818637 6.28402 1.05495 6.00013 1.05495H3.25721C2.02839 1.05495 1.02859 2.08035 1.02859 3.34066V10.3736C1.02859 11.6339 2.02839 12.6593 3.25721 12.6593H6.00013C6.28402 12.6593 6.51443 12.8956 6.51443 13.1868ZM11.8494 6.48438L9.10648 3.67119C8.90556 3.46512 8.57984 3.46512 8.37892 3.67119C8.178 3.87725 8.178 4.21134 8.37892 4.41741L10.2441 6.33036H4.62867C4.34478 6.33036 4.11438 6.56667 4.11438 6.85783C4.11438 7.14899 4.34478 7.3853 4.62867 7.3853H10.2441L8.37892 9.29825C8.178 9.50432 8.178 9.83841 8.37892 10.0445C8.47904 10.1472 8.6107 10.1992 8.74236 10.1992C8.87402 10.1992 9.00568 10.1479 9.10579 10.0445L11.8487 7.23128C12.0503 7.02381 12.0503 6.69044 11.8494 6.48438Z" fill="white" />
</svg>
);

export const GreenEllipse = ({ fill = '#7E8E51', ...props }) => (
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g filter="url(#filter0_d_1062_5930)">
      <circle cx="16" cy="12" r="6" fill={fill} />
    </g>
    <defs>
      <filter id="filter0_d_1062_5930" x="0" y="0" width="32" height="32" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
        <feOffset dy="4" />
        <feGaussianBlur stdDeviation="5" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix type="matrix" values="0 0 0 0 0.494118 0 0 0 0 0.556863 0 0 0 0 0.317647 0 0 0 0.5 0" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1062_5930" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1062_5930" result="shape" />
      </filter>
    </defs>
  </svg>
);
export { default as DashboardIcon } from "./DashboardIcon";
export { default as StudentsIcon } from "./StudentsIcon";
export { default as ParentsIcon } from "./ParentsIcon";
export { default as StaffsIcon } from "./StaffsIcon";
export { default as CategoriesIcon } from "./CategoriesIcon";
export { default as ResourcesIcon } from "./ResourcesIcon";
export { default as NotificationsIcon } from "./NotificationsIcon";
export { default as FeedbackIcon } from "./FeedbackIcon";
export { default as SearchIcon } from "./SearchIcon";
export { default as ModalCloseIcon } from "./ModalCloseIcon";
export { default as PlusIcon } from './PlusIcon';
export { default as EditIcon } from './EditIcon';
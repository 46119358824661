import { AxiosRequestConfig } from 'axios';
import { take, call, put, select, takeLatest, delay } from 'redux-saga/effects';
import { resourcesActions as actions } from '.';
import request from '../../../../utils/request';
import { layoutActions } from '../../Layout/slice';

function* fetchResourcesList({ payload }) {
  yield delay(500);
  yield put(layoutActions.setLoading(true));
  const { search, page, pageSize, filters } = payload;

  let url = `/resources/?search=${encodeURIComponent(
    search,
  )}${page ? '&page=' + page : ''}&pageSize=${pageSize}${filters?.categories ? '&categories=' + filters?.categories : ''}${filters?.grades ? '&grades=' + filters?.grades : ''}`;
  const options: AxiosRequestConfig = {
    method: 'GET',
  };
  try {
    const response = yield call(
      request,
      url,
      options,
    );
    yield put(actions.setResourcesList(response));
    yield put(layoutActions.setLoading(false));
  } catch (ex: any) {
    yield put(layoutActions.setError(ex.message));
    yield put(layoutActions.setLoading(false));
  }
}

function* deleteResourceById({ payload }) {
  yield delay(500);
  yield put(layoutActions.setLoading(true));
  const { id } = payload;
  let url = `/resources/${id}`;
  const options: AxiosRequestConfig = {
    method: 'DELETE',
  };
  try {
    yield call(request, url, options);
    yield put(actions.getResourcesList({
      search: '', page: 1, pageSize: 20
    }));
    yield put(layoutActions.setLoading(false));
    yield put(layoutActions.setSuccess("Resource removed successfully"));
  } catch (ex: any) {
    yield put(layoutActions.setError(ex.message));
    yield put(layoutActions.setLoading(false));
  }
}

function* updateResourceById({ payload }) {
  yield delay(500);
  yield put(layoutActions.setLoading(true));
  const { id, data } = payload;
  let url = `/resources/${id}`;
  const options: AxiosRequestConfig = {
    method: 'PATCH',
    data: data
  };
  try {
    yield call(request, url, options);
    yield put(actions.getResourcesList({
      search: '', page: 1, pageSize: 20
    }));
    yield put(layoutActions.setLoading(false));
    yield put(actions.setAddedResource(true));
    yield put(layoutActions.setSuccess("Resource updated successfully"));
  } catch (ex: any) {
    yield put(layoutActions.setError(ex.message));
    yield put(layoutActions.setLoading(false));
  }
}

function* addNewResource({ payload }) {
  yield delay(500);
  yield put(layoutActions.setLoading(true));
  const { data } = payload;
  let url = `/resources/`;
  const options: AxiosRequestConfig = {
    method: 'POST',
    data: data
  };
  try {
    yield call(request, url, options);
    yield put(actions.getResourcesList({
      search: '', page: 1, pageSize: 20
    }));
    yield put(layoutActions.setLoading(false));
    yield put(actions.setAddedResource(true));
    yield put(layoutActions.setSuccess("Resource added successfully"));
  } catch (ex: any) {
    yield put(layoutActions.setError(ex.message));
    yield put(layoutActions.setLoading(false));
  }
}
export function* resourcesSaga() {
  yield takeLatest(actions.getResourcesList, fetchResourcesList);
  yield takeLatest(actions.deleteResourceById, deleteResourceById);
  yield takeLatest(actions.updateResourceById, updateResourceById);
  yield takeLatest(actions.addNewResource, addNewResource);
}

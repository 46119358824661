import React from 'react';

interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  label: string | JSX.Element;
  variation?:
  | 'primary'
  | 'secondary'
  | 'file-upload'
  | 'edit'
  | 'login'
  | 'file-select'
  | 'thumbnail-upload'
  | 'action'
  | 'delete';
}

export default function Button({
  label,
  variation = 'primary',
  ...props
}: ButtonProps) {
  return (
    <button className={`btn btn-${variation}`} {...props}>
      {label}
    </button>
  );
}

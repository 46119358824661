import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from 'utils/@reduxjs/toolkit';
import { useInjectReducer, useInjectSaga } from 'utils/redux-injectors';
import { parentsSaga } from './saga';
import { AddParentRequest, AddStudentsToParentRequest, ParentsState } from './types';

export const initialState: ParentsState = {
  isAdded: null
};

const slice = createSlice({
  name: 'parents',
  initialState,
  reducers: {
    addParentsSubmit(state, action: PayloadAction<{ data: AddParentRequest; }>) { },
    addStudentsToParent(state, action: PayloadAction<{ data: AddStudentsToParentRequest; }>) { },
    setIsAdded(state, action: PayloadAction<boolean | null>) { state.isAdded = action.payload; },
  },
});

export const { actions: parentsActions } = slice;

export const useParentsSlice = () => {
  useInjectReducer({ key: slice.name, reducer: slice.reducer });
  useInjectSaga({ key: slice.name, saga: parentsSaga });
  return { actions: slice.actions };
};

/**
 * Example Usage:
 *
 * export function MyComponentNeedingThisSlice() {
 *  const { actions } = useParentsSlice();
 *
 *  const onButtonClick = (evt) => {
 *    dispatch(actions.someAction());
 *   };
 * }
 */
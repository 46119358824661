import { Modal, Form, Input, ModalFuncProps, FormInstance, Row, Col, Spin } from 'antd';
import Button from '../../../components/Button';
import { GreenEllipse, ModalCloseIcon } from '../../../components/icons';
import DeleteIcon from '../../../components/icons/DeleteIcon';
import "../styles.scss";
import { UserInformation } from '../../Dashboard/slice/types';
import { phoneValidation } from '../../../../utils/commonMethods';
import { useEffect } from 'react';

const FormItem = Form.Item;
interface IProps extends ModalFuncProps {
  form: FormInstance<any>;
  onFinish: ((values: any, parentId?: string | number) => void);
  loading: boolean;
  parentDetails?: UserInformation | null;
}
const AddParentForm = (props: IProps) => {
  const { open, onCancel, onFinish, form, loading, parentDetails } = props;
  useEffect(() => {
    form.setFieldValue('phone_number', parentDetails?.phone_number);
    form.setFieldValue('email', parentDetails?.email);
    form.setFieldValue('name', parentDetails?.name);
  }, []);
  return (
    <Modal
      open={open}
      title={<><GreenEllipse />  {parentDetails ? "Add New Student" : "Add Parent"} </>}
      onCancel={onCancel}
      closeIcon={<ModalCloseIcon />}
      footer={[
        <Button label={"Add"} variation="action" onClick={() => form.submit()} />,
      ]}
      className="custom-modal"
      destroyOnClose={true}
      style={{ width: '600px' }}
      {...props}
    >
      <Spin spinning={loading}>
        <Form
          preserve={false}
          layout="vertical"
          form={form}
          onFinish={(values) => onFinish(values, parentDetails?.id)}
          className="custom-add-form"
          key={"form"}
          initialValues={{ ...parentDetails, children: [""] }}
          requiredMark={false}
        >
          <Row gutter={20} key="first">
            <Col span={8}>
              <FormItem
                label="Parent full name*"
                name="name"
                rules={[{ required: true, message: 'Enter the parent name!' }]}>
                <Input
                  placeholder="Enter parent full name"
                />
              </FormItem>
            </Col>
            <Col span={8}>
              <FormItem
                label="Email*"
                name="email"
                rules={[
                  { required: true, message: 'Enter the parent email!' },
                  {
                    type: 'email',
                    message: 'Invalid email!',
                  },
                ]}>
                <Input
                  placeholder="Enter parent email"
                  type='email'
                />
              </FormItem>
            </Col>
            <Col span={8}>
              <FormItem
                label="Phone number*"
                name="phone_number"
                rules={[
                  // { required: true, message: 'Enter the parent phone number!' },
                  {
                    validator(_, value) {
                      return phoneValidation(value);
                    },
                  },
                ]}>
                <Input
                  placeholder="Enter parent phone number"
                />
              </FormItem>
            </Col>
          </Row>
          <Form.List name="children">
            {(fields, { add, remove }) => (
              <div className='add-more-container'>
                {fields.map(({ key, name, ...restField }) => (
                  <Row gutter={20} key="third">
                    <Col span={8}>
                      <FormItem
                        {...restField}
                        name={[name, 'name']}
                        label="Student full name*"
                        rules={[
                          {
                            required: true,
                            message: 'Enter the student name!',
                          },
                        ]}
                      >
                        <Input placeholder="Enter student full name" />
                      </FormItem>
                    </Col>
                    <Col span={8}>
                      <FormItem
                        {...restField}
                        name={[name, 'email']}
                        label="Email*"
                        rules={[
                          { required: true, message: 'Enter the student email!' },
                          {
                            type: 'email',
                            message: 'Invalid email!',
                          },
                        ]}>
                        <Input placeholder="Enter student email" />
                      </FormItem>
                    </Col>
                    <Col span={8}>
                      <FormItem
                        {...restField}
                        name={[name, 'phone_number']}
                        label="Phone number*"
                        rules={[
                          // { required: true, message: 'Enter the student phone number!' }
                          {
                            validator(_, value) {
                              return phoneValidation(value);
                            },
                          },
                        ]}>
                        <Input placeholder="Enter student phone number" />
                      </FormItem>
                    </Col>

                    {name !== 0 && <div onClick={() => remove(name)} className='minus-btn' ><DeleteIcon /> </div>}

                  </Row>
                ))}
                <Form.Item>
                  <div onClick={() => add()} className="add-more">+ Add more</div>
                </Form.Item>
              </div>
            )}
          </Form.List>
        </Form>
      </Spin>
    </Modal >
  );
};
export default AddParentForm;;

import { ImportOutlined } from "@ant-design/icons";
import { Upload, UploadFile, notification } from "antd";
import axios from "axios";
import { useState } from "react";
import { API_URL } from "../../../settings";
import { auth } from "../../../utils/auth";
import { validateFileType } from "../../../utils/commonMethods";
import { dashboardActions } from "../../modules/Dashboard/slice";
import Button from "../Button";
import { useDispatch } from "react-redux";
import "./styles.scss";
import parseDjangoError from "../../../utils/parseDjangoError";

const BulkImport = ({ account_type }) => {
  const [defaultFileList, setDefaultFileList] = useState<any>(null);
  const dispatch = useDispatch();
  const handleFileUpload = (file: UploadFile) => {
    console.log("file uo : ", file);

    const isAllowedType = validateFileType(file, ['csv']);
    if (!isAllowedType) {
      setDefaultFileList(null);
      return false;
    }
    setDefaultFileList(file);
    return true;
  };

  const uploadImage = async options => {
    const { onSuccess, onError, file } = options;

    const fmData = new FormData();
    const config = {
      headers: {
        "content-type": "multipart/form-data",
        authorization: 'Token ' + auth.getToken()
      },
    };
    fmData.append("file", file);
    try {
      const res = await axios.post(
        `${API_URL}users/upload/all/csv/`,
        fmData,
        config
      );
      onSuccess("Ok");
      console.log("server res: ", res);
    } catch (err: any) {
      console.log("Eroor: err : ", err?.response?.data);
      // onError("Error");
      notification.error({
        message: parseDjangoError(err?.response?.data)
      });
      setDefaultFileList(null);
    }
  };

  const handleOnChange = (info: any) => {
    console.log("Before", info.fileList);
    if (info.fileList.length > 0 && validateFileType(info.fileList[0], ['csv']))
      setDefaultFileList(info.fileList);
    else {
      setDefaultFileList(null);
      notification.error({
        message: `The file format is incorrect. The file must be in CSV format.`,
      });
      return;
    }
    if (info.file.status !== "uploading") {
      let reader = new FileReader();
      reader.onload = (e) => {
        console.log(e.target?.result);
      };
      reader.readAsText(info.file.originFileObj);
    }
    if (info.file.status === "done") {
      info.fileList.length = 0; // this line worked for me
      if (info.file.response.status === "error") {
        notification.error({
          message: `${info.file.name} file couldn't upload! Something went wrong!!!`,
        });
      } else {
        notification.success({
          message: `${info.file.name} file uploaded successfully!`,
        });
        setDefaultFileList(null);

        dispatch(dashboardActions.getUserList({
          search: '', page: 1, pageSize: 20, account_type: account_type
        }));
      }
    } else if (info.file.status === "error") {
      info.fileList.length = 0; // // this line worked for me
      notification.error({
        message: `${info.file.name} file upload failed!`,
      });
    }

    console.log("After", info.fileList);
  };

  return (
    <>
      <Upload
        accept=".csv"
        customRequest={uploadImage}
        onChange={handleOnChange}
        multiple={false}
        beforeUpload={(file) => { return handleFileUpload(file); }}
        showUploadList={!!defaultFileList}
      >
        {!defaultFileList && <Button
          label={<span><ImportOutlined /> Bulk Import</span>}
          variation="action"
          style={{ width: '100%', height: '48px', background: '#7E8E51' }}
        />}
      </Upload>
    </>
  );
};


export default BulkImport;
/**
*
* ParentsDetails
*
*/
import { Spin, Form } from 'antd';
import { memo, useEffect, useState } from 'react';
import { UserInformation } from '../../Dashboard/slice/types';
import { useDispatch, useSelector } from 'react-redux';
import { dashboardActions } from '../../Dashboard/slice';
import UserDetailsTopSection from '../../../components/UserDetailsTopSection';
import KidsCard from '../../../components/KidsCard';
import Button from '../../../components/Button';
import "../styles.scss";
import { selectStaffTeachers } from '../slice/selectors';
import { staffTeachersActions, useStaffTeachersSlice } from '../slice';
import { EditIcon } from '../../../components/icons';

interface Props {
  teacherDetails: UserInformation;
  handleDelete: (id: any) => void;
  setOpenFormModal: any;
}

export const TeacherDetails = memo((props: Props) => {
  const { teacherDetails, handleDelete, setOpenFormModal } = props;
  const [loading, setLoading] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const { actions } = useStaffTeachersSlice();
  const { teachersChildrenList } = useSelector(selectStaffTeachers);
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  const handleSaveInfo = (values, imageFile) => {
    setLoading(true);
    if (imageFile) values.profile_image = imageFile;
    dispatch(dashboardActions.updateUserInformation({
      id: teacherDetails.id,
      data: values,
      account_type: teacherDetails.account_type
    }));
    setIsEdit(false);
    form.resetFields();
  };

  const handleKidsDelete = (data: UserInformation) => {
    setLoading(true);
    dispatch(dashboardActions.updateUserInformation({
      id: data.id,
      data: { teacher: '' },
      account_type: teacherDetails.account_type
    }));
    // setLoading(true);
    // dispatch(actions.getTeachersChildren({ id: teacherDetails.id }));
  };

  useEffect(() => {
    if (teacherDetails) {
      setLoading(true);
      setIsEdit(false);
      dispatch(actions.getTeachersChildren({ id: teacherDetails.id }));
    }
    else
      dispatch(actions.setTeachersChildren(null));
  }, [teacherDetails]);

  useEffect(() => {
    if (teachersChildrenList) setLoading(false);
  }, [teachersChildrenList]);
  return (
    <Spin spinning={loading}>
      <div className='right-sidebar-container'>
        <div style={{ width: '100%' }}>
          <UserDetailsTopSection userDetails={teacherDetails} form={form} isEdit={isEdit} onFinish={handleSaveInfo} />
          <div className='border-bottom'></div>
          <div>
            <span className='right-sidebar-container--title'>Number of Students: {teachersChildrenList?.count}</span>
            <div className='flex flex--direction-column' style={{ padding: '15px 0px', rowGap: '1px' }}>
              {teachersChildrenList?.results?.map(item =>
                <KidsCard
                  userDetails={item}
                  key={item.id}
                  isEdit={isEdit}
                  handleDelete={() => handleKidsDelete(item)}
                />
              )}
            </div>
          </div>
          {!isEdit &&
            <div className='right-sidebar-container-edit-icon' onClick={() => setIsEdit(true)}><EditIcon /></div>
          }
          {isEdit && <p className='add-new-btn' onClick={() => setOpenFormModal(true)}>+ Assign Students</p>}
        </div>
        {isEdit ? (
          <div className='flex' style={{ columnGap: '4px', width: '100%' }}>
            <Button
              label={<span>Save</span>}
              variation="action"
              style={{ width: '100%', height: '48px' }}
              onClick={() => form.submit()}
            />
            <Button
              label={<span>Cancel</span>}
              variation="action"
              style={{ width: '100%', height: '48px', background: '#6B7579', border: '1px solid white' }}
              onClick={() => setIsEdit(false)}
            />
          </div>
        )
          :
          <div>
            <Button label={<span> Delete</span>} variation="delete" style={{ height: '48px', marginTop: '16px' }}
              onClick={() => { handleDelete(teacherDetails.id); }}
            />
          </div>
        }
      </div>
    </Spin >
  );
});


import { PayloadAction } from '@reduxjs/toolkit';
import { Key } from 'react';
import { createSlice } from 'utils/@reduxjs/toolkit';
import { useInjectReducer, useInjectSaga } from 'utils/redux-injectors';
import { dashboardSaga } from './saga';
import { DashboardState, ParentsChildrenResponse, SubjectResponse, UserCounts, UserInformation } from './types';

export const initialState: DashboardState = {
  studentList: [],
  parentList: [],
  teacherList: [],
  userList: [],
  categoryList: [],
  schoolClassList: [],
  subjectList: null,
  userInformation: null,
  parentsChildrenList: null,
  userCounts: {
    "students": 0,
    "teachers": 0,
    "parents": 0,
    "admins": 0
  },
  isAddedUser: null
};

const slice = createSlice({
  name: 'dashboard',
  initialState,
  reducers: {
    getUserList(state, action: PayloadAction<any>) { },
    getParentList(state, action: PayloadAction<any>) { },
    getTeacherList(state, action: PayloadAction<any>) { },
    getSchoolClassList(state) { },
    getSubjectList(state) { },
    setSubjectList(state, action: PayloadAction<SubjectResponse>) {
      state.subjectList = action.payload;
    },
    getParentsChildren(state, action: PayloadAction<{ id: string | number; }>) { },
    setParentsChildren(state, action: PayloadAction<ParentsChildrenResponse | null>) {
      state.parentsChildrenList = action.payload;
    },
    setSchoolClassList(state, action: PayloadAction<any>) {
      state.schoolClassList = action.payload;
    },
    setStudentList(state, action: PayloadAction<any>) {
      state.studentList = action.payload;
    },
    setParentList(state, action: PayloadAction<any>) {
      state.parentList = action.payload;
    },
    setTeacherList(state, action: PayloadAction<any>) {
      state.teacherList = action.payload;
    },
    setUserList(state, action: PayloadAction<any>) {
      state.userList = action.payload;
    },
    getCategoryList(state, action: PayloadAction<any>) { },
    setCategoryList(state, action: PayloadAction<any>) {
      state.categoryList = action.payload;
    },
    setUserCounts(state, action: PayloadAction<UserCounts>) {
      state.userCounts = action.payload;
    },
    getUserCounts(state) { },
    getUserInformationById(state, action: PayloadAction<{ id: string | Number; }>) { },
    setUserInformationById(state, action: PayloadAction<UserInformation | null>) {
      state.userInformation = action.payload;
    },
    updateUserInformation(state, action: PayloadAction<{ id: any, data: any, account_type: string; }>) { },
    updateUserStatus(state, action: PayloadAction<{ username: string, status: 'activate' | 'deactivate' | 'remove' | 'removeall'; }>) { },
    deleteUserInformation(state, action: PayloadAction<{ username: string, account_type: string; }>) { },
    deleteSelectedUser(state, action: PayloadAction<{ user_list: Key[], account_type: string; }>) { },
    addNewTeacherSubmit(state, action: PayloadAction<{ data: UserInformation; }>) { },
    setIsAddedUser(state, action: PayloadAction<boolean | null>) { state.isAddedUser = action.payload; }
  },
});

export const { actions: dashboardActions } = slice;

export const useDashboardSlice = () => {
  useInjectReducer({ key: slice.name, reducer: slice.reducer });
  useInjectSaga({ key: slice.name, saga: dashboardSaga });
  return { actions: slice.actions };
};

/**
 * Example Usage:
 *
 * export function MyComponentNeedingThisSlice() {
 *  const { actions } = useDashboardSlice();
 *
 *  const onButtonClick = (evt) => {
 *    dispatch(actions.someAction());
 *   };
 * }
 */
/**
*
* Students
*
*/
import { Col, Form, Image, Row, Table } from 'antd';
import React, { memo, useEffect, useReducer, useState } from 'react';
import Button from '../../components/Button';
import PageTitle from '../../components/Header/PageTitle';
import SearchInput from '../../components/Header/SearchInput';
import { Layout } from '../Layout';
// import DefaultImage from "../../../assets/icons/default-image.svg";
import "./styles.scss";
import { useDispatch, useSelector } from 'react-redux';
import { selectDashboard } from '../Dashboard/slice/selectors';
import { useDashboardSlice } from '../Dashboard/slice';
import DeleteIcon from '../../components/icons/DeleteIcon';
import Modal from '../../components/Modal';
import { ModalTypes } from '../../../constant';
import { UserInformation } from '../Dashboard/slice/types';
import { StudentsDetails } from './components/StudentsDetails';
import { selectLayout } from '../Layout/slice/selectors';
import { getFullName } from '../../../utils';
import { GreenEllipse } from '../../components/icons';
import { useResourcesSlice } from '../Resources/slice';
import DropdownIcon from '../../components/icons/DropdownIcon';
import AssignResourceForm from './components/AssignResourceForm';
import { selectResources } from '../Resources/slice/selectors';
import { auth } from '../../../utils/auth';
import { useStudentsSlice } from './slice';
import { selectStudents } from './slice/selectors';
import BulkImport from '../../components/BulkImport';
import DefaultImage from '../../components/icons/DefaultImage';

interface Props { }

export const Students = memo((props: Props) => {
  const { studentList, schoolClassList } = useSelector(selectDashboard);
  const { loading } = useSelector(selectLayout);
  const { resourcesList } = useSelector(selectResources);
  const { isAssignResource, studentsResourceList } = useSelector(selectStudents);
  const dispatchRedux = useDispatch();
  const { actions } = useDashboardSlice();
  const resourcesActions = useResourcesSlice();
  const studentsActions = useStudentsSlice();
  const [search, setSearch] = useState('');
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(20);
  const [selectedUser, setSelectedUser] = useState<UserInformation | null>(null);
  const [grades, setGrades] = useState(null);
  const [openFormModal, setOpenFormModal] = useState(false);
  const [form] = Form.useForm();
  const currentUser = auth.getUserData();
  const ACTION_TYPES = {
    HANDLE_MODAL: 'HANDLE_MODAL',
  };
  const reducer = (state: any, action: {
    type: any;
    payload: {
      isModalVisible: boolean;
      modalTypes: any;
      modalId: string | null | Number | React.Key[];
    };
  }) => {
    switch (action.type) {
      case ACTION_TYPES.HANDLE_MODAL:
        return {
          ...state,
          isModalVisible: action.payload.isModalVisible,
          modalTypes: action.payload.modalTypes,
          modalId: action.payload.modalId
        };
      default:
        return state;
    }
  };
  const [state, dispatch] = useReducer(reducer, {
    isModalVisible: false,
    modalTypes: ModalTypes.activate,
    modalId: null
  });
  const columns = [
    {
      title: 'Image',
      dataIndex: 'profile_image',
      width: '20%',
      render: (_, row) => {
        return (
          row.profile_image ?
            <Image
              src={row.profile_image}
              alt={row.name}
              height={34}
              width={34}
              style={{ borderRadius: '50%' }}
              preview={false}
            />
            :
            <DefaultImage />);
      }
    },
    {
      title: 'Name',
      dataIndex: 'name',
      width: '30%',
      render: (_, row) => (
        <div>{getFullName(row)}</div>
      )
    },
    {
      title: 'Grade',
      dataIndex: 'grades',
      width: '25%',
      render: (_, row) => (
        <div>{schoolClassList?.results?.find(item => item.id === row.grade)?.name}</div>
      ),
      filterIcon: () => <DropdownIcon />,
      filters: schoolClassList?.results?.map(item => { return { text: item.name, value: item.id }; })
    },
    {
      title: 'Action',
      render: (_: any, row: UserInformation) => {
        return (
          <div className='table-action-btn'>
            {
              !row.is_active ? <Button
                key={`activate-${row.id}`}
                variation="primary"
                label={"Activate"}
                onClick={() => {
                  dispatch({
                    type: ACTION_TYPES.HANDLE_MODAL,
                    payload: { isModalVisible: true, modalTypes: ModalTypes.activate, modalId: row.id },
                  });
                }
                }
              />
                :
                <Button
                  key={`action-${row.id}`}
                  label="Deactivate"
                  variation='secondary'
                  onClick={() => {
                    dispatch({
                      type: ACTION_TYPES.HANDLE_MODAL,
                      payload: { isModalVisible: true, modalTypes: ModalTypes.deactivate, modalId: row.id },
                    });
                  }
                  }
                />
            }
            <div
              className='search-icon' onClick={() => {
                handleDelete(row.id);
              }}>
              <DeleteIcon />
            </div>
          </div>
        );
      },
      width: '25%',
    },
  ];
  useEffect(() => {
    dispatchRedux(actions.setStudentList([]));
    setSelectedUser(null);
    setTimeout(() => {
      dispatchRedux(actions.getUserList({
        search: search, page: page, pageSize: pageSize, account_type: 'student'
      }));
    }, 500);
    dispatchRedux(actions.getSchoolClassList());
    dispatchRedux(resourcesActions.actions.getResourcesList({ search: '' }));
  }, []);
  useEffect(() => {
    if (isAssignResource) {
      setOpenFormModal(false);
      form.resetFields();
      dispatchRedux(studentsActions.actions.setIsAssignResource(null));
    }
  }, [isAssignResource]);
  useEffect(() => {
    setSelectedUser(null);
    if (studentList.results) {
      setSelectedUser(studentList?.results[0]);
    }
  }, [studentList]);
  useEffect(() => {
    dispatchRedux(actions.getUserList({
      search: search, page: page, pageSize: pageSize, account_type: 'student', grades: grades,
    }));
  }, [search, page, grades, pageSize]);

  const handleSearch = (val: React.SetStateAction<string>) => {
    setSearch(val);
  };
  const handleDelete = (id, type = null) => {
    if (type) {
      dispatch({
        type: ACTION_TYPES.HANDLE_MODAL,
        payload: { isModalVisible: true, modalTypes: ModalTypes.resource, modalId: id },
      });
    }
    else
      dispatch({
        type: ACTION_TYPES.HANDLE_MODAL,
        payload: { isModalVisible: true, modalTypes: ModalTypes.remove, modalId: id },
      });
  };
  const handleCancel = () => {
    dispatch({
      type: ACTION_TYPES.HANDLE_MODAL,
      payload: { isModalVisible: false, modalTypes: ModalTypes.activate, modalId: null },
    });
  };
  const handleOk = (status: 'activate' | 'deactivate' | 'remove' | 'remove all' | 'remove resource') => {
    if (status === 'remove resource') {
      const data = [{
        student: selectedUser?.id,
        resource: state.modalId,
        assigned_by: currentUser.id
      }];
      dispatchRedux(studentsActions.actions.deleteAssignedResource({ data: data, studentId: selectedUser?.id }));
    }
    else if (status === 'remove')
      dispatchRedux(actions.deleteUserInformation({ username: state.modalId, account_type: 'student' }));
    else if (status === 'remove all') {
      dispatchRedux(actions.deleteSelectedUser({ user_list: state.modalId, account_type: 'student' }));
      setSelectedRowKeys([]);
    }
    else
      dispatchRedux(actions.updateUserStatus({ username: state.modalId, status: status }));
    dispatch({
      type: ACTION_TYPES.HANDLE_MODAL,
      payload: { isModalVisible: false, modalTypes: ModalTypes.activate, modalId: null },
    });
  };
  const handleAssignResource = (values, student) => {
    const data: any = [];
    values.resource.forEach(element =>
      data.push({
        student: student,
        resource: element,
        assigned_by: currentUser.id
      })
    );

    dispatchRedux(studentsActions.actions.assignResourceSubmit({ data: data, student: student }));
  };
  // Table Row Selection
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const onChange = (pagination, filters, sorter, extra) => {
    setGrades(filters.grades);
  };

  return (
    <Layout>
      <Row className="header-container flex--justify-between" style={{ marginTop: '16px' }}>
        <Col span={5}>
          <PageTitle title={"Students"} subtitle={''} titleIcon={<GreenEllipse />} />
        </Col>
        <Col span={selectedRowKeys.length > 0 ? 10 : 14}>
          <SearchInput handleSearch={handleSearch} />
        </Col>
        {selectedRowKeys.length > 0 &&
          <Col span={4}>
            <Button
              label={<span><DeleteIcon color='white' /> Delete</span>}
              variation="delete"
              style={{ width: '100%', height: '48px' }}
              disabled={selectedRowKeys.length <= 0}
              onClick={() => {
                dispatch({
                  type: ACTION_TYPES.HANDLE_MODAL,
                  payload: { isModalVisible: true, modalTypes: ModalTypes.removeall, modalId: selectedRowKeys },
                });
              }} />
          </Col>
        }
        <Col span={4}>
          <BulkImport account_type={"student"} />
        </Col>
      </Row>
      <Row className='students-main-container'>
        <Col span={16}>
          <Table
            loading={loading}
            columns={columns}
            dataSource={studentList?.results}
            pagination={{
              current: page,
              total: studentList?.count,
              onChange(page, pageSize) {
                setPage(page);
                setPageSize(pageSize);
              },
              pageSize: pageSize,
            }}
            onChange={onChange}
            rowSelection={rowSelection}
            rowKey={obj => obj.id.toString()}
            onRow={(record, rowIndex) => {
              return {
                onClick: () => {
                  setSelectedUser(record);
                }, // click row
              };
            }}
          />
        </Col>
        <Col span={8} style={{ paddingLeft: '15px' }}>
          {selectedUser && studentList?.results?.length > 0 &&
            <StudentsDetails
              studentDetails={selectedUser}
              handleDelete={handleDelete}
              setOpenFormModal={setOpenFormModal}
              gradeOptions={schoolClassList?.results?.map(item => { return { value: item.id, label: item.name }; })}
            />
          }
        </Col>
      </Row>
      {
        openFormModal &&
        <AssignResourceForm
          loading={loading}
          onFinish={handleAssignResource}
          open={openFormModal}
          form={form}
          studentDetails={selectedUser}
          onCancel={() => setOpenFormModal(false)}
          resourcesList={resourcesList?.results?.filter(obj => {
            return !studentsResourceList?.results?.some(filterObj => filterObj.resource.id === obj.id);
          }).map(item => { return { value: item.id, label: item.name }; })}
        />
      }
      <Modal
        handleCancel={handleCancel}
        handleOk={() => handleOk(state.modalTypes.title.toLowerCase())}
        isModalVisible={state.isModalVisible}
        message={state.modalTypes.message}
        title={<><GreenEllipse /> {state.modalTypes.title}</>}
        btnTitle={state.modalTypes.btnTitle}
      />
    </Layout >
  );

});
;

/**
 * Create the store with dynamic reducers
 */

import {
  configureStore,
  getDefaultMiddleware,
  StoreEnhancer,
} from '@reduxjs/toolkit';
import { createInjectorsEnhancer } from 'redux-injectors';
import createSagaMiddleware from 'redux-saga';
import {
  persistReducer,
  persistStore,
} from "redux-persist";

import { createReducer } from './reducers';
import storage from 'redux-persist/lib/storage';

export function configureAppStore() {
  const reduxSagaMonitorOptions = {};
  const sagaMiddleware = createSagaMiddleware(reduxSagaMonitorOptions);
  const { run: runSaga } = sagaMiddleware;

  // Create the store with saga middleware
  const middlewares = [sagaMiddleware];

  const enhancers = [
    createInjectorsEnhancer({
      createReducer,
      runSaga,
    }),
  ] as StoreEnhancer[];

  const authPersistConfig = {
    key: 'auth',
    storage: storage,
    whitelist: ['token', 'username'],
  };

  const authReducer = persistReducer(authPersistConfig, createReducer());

  const store = configureStore({
    reducer: authReducer,
    middleware: [
      ...getDefaultMiddleware({ serializableCheck: false }),
      ...middlewares,
    ],
    devTools: process.env.NODE_ENV !== 'production',
    enhancers,
  });

  const persistor = persistStore(store);
  store['persistor'] = persistor;

  return { store, persistor };
}

import { Modal, Form, Input, ModalFuncProps, FormInstance, Select, Row, Col, Spin, Upload } from 'antd';
import { useEffect, useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { UploadOutlined } from '@ant-design/icons';
import { auth } from '../../../../utils/auth';
import Button from '../../../components/Button';
import type { UploadFile } from 'antd/es/upload/interface';
import CustomMultiSelect from '../../../components/CustomMultiSelect';
import { GreenEllipse, ModalCloseIcon } from '../../../components/icons';
import { useDashboardSlice } from '../../Dashboard/slice';
import { selectDashboard } from '../../Dashboard/slice/selectors';
import { allOptions, getListofId, getReduceList } from '../config';
import { SendNotificationRequest } from '../slice/types';
import { AllowedFileFormat, CustomFileValidator, validateFileSize, validateFileType } from '../../../../utils/commonMethods';
import { API_URL } from '../../../../settings';
import { layoutActions } from '../../Layout/slice';

const FormItem = Form.Item;
interface IProps extends ModalFuncProps {
  form: FormInstance<any>;
  onFinish: ((values: any) => void);
  accountTypeOptions: { value: string | number, label: string; }[];
  gradeOptions: { value: string | number, label: string; }[];
  loading: boolean;
}
const NotificationSendForm = (props: IProps) => {
  const dispatch = useDispatch();
  const dashboardActions = useDashboardSlice();
  const currentUser = auth.getUserData();
  const { open, onCancel, onFinish, form, accountTypeOptions, gradeOptions, loading } = props;
  const [selectedOptions, setSelectedOptions] = useState<{
    students: number[] | string[];
    teachers: number[] | string[];
    parents: number[] | string[];
  }>({
    students: [],
    teachers: [],
    parents: []
  });
  const [dirty, setDirty] = useState(false);
  const { control, watch, setValue } = useForm();
  const handleSelectedOptions = (value: string[], type: string) => {
    setDirty(true);
    if (selectedOptions[type].includes('-1') && !value.includes('-1')) {
      form.setFieldValue(type, []);
      setSelectedOptions({
        ...selectedOptions,
        [type]: []
      });
    }
    else if (value.includes('-1') && gradeOptions.length + 1 !== selectedOptions[type].length) {
      const allOptions: any = ['-1', ...gradeOptions.map(item => item.value)];
      form.setFieldValue(type, allOptions);
      setSelectedOptions({
        ...selectedOptions,
        [type]: allOptions
      });
    }
    else {
      const opt = value.filter(item => item !== '-1');
      form.setFieldValue(type, opt);
      setSelectedOptions({ ...selectedOptions, [type]: opt });
    }
  };
  const [studentOptions, setStudentOptions] = useState([allOptions]);
  const [teacherOptions, setTeacherOptions] = useState([allOptions]);
  const [parentOptions, setParentOptions] = useState([allOptions]);
  const [file, setFile] = useState<UploadFile | null>(null);
  const [attachment, setAttachment] = useState<number | string | null>(null);
  const { parentList, studentList, teacherList, userList } = useSelector(selectDashboard);

  const onSendHandle = (values) => {
    const data: SendNotificationRequest = {
      message: values.message,
      title: values.title,
      sender: currentUser.id,
      receivers: [],
    };
    if (attachment) data['attachment'] = attachment;
    if (values.students === "-1") data.receivers = [...data.receivers, ...getListofId(studentOptions)];
    else if (values.students) data.receivers.push(values.students);
    if (values.parents === "-1") data.receivers = [...data.receivers, ...getListofId(parentOptions)];
    else if (values.parents) data.receivers.push(values.parents);
    if (values.teachers === "-1") data.receivers = [...data.receivers, ...getListofId(teacherOptions)];
    else if (values.teachers) data.receivers.push(values.teachers);
    if (data.receivers.length > 0)
      onFinish(data);
    else dispatch(layoutActions.setError("Empty receivers!!! At least one receivers must be selected."));
  };

  const handleFileUpload = (file: UploadFile) => {
    const isAllowedType = validateFileType(file, AllowedFileFormat);
    if (!isAllowedType) {
      setFile(null);
      setAttachment(null);
      return false;
    }
    if (!validateFileSize(file, 3)) {
      setFile(null);
      setAttachment(null);
      return false;
    }
    setFile(file);
    return true;
  };

  const handleFileRemove = () => {
    setFile(null);
    setAttachment(null);
  };
  useEffect(() => {
    dispatch(dashboardActions.actions.getUserList({}));
  }, []);

  useEffect(() => {
    const payload = {};
    if (watch('account_type') !== '-1') {
      payload['account_type'] = watch('account_type');
      form.setFieldValue('students', watch('account_type') === 'student' ? '-1' : null);
      form.setFieldValue('parents', watch('account_type') === 'parent' ? '-1' : null);
      form.setFieldValue('teachers', watch('account_type') === 'teacher' ? '-1' : null);
      setValue('grades', watch('account_type') !== 'teacher' ? '-1' : null);
    }
    else {
      form.setFieldValue('students', '-1');
      form.setFieldValue('parents', '-1');
      form.setFieldValue('teachers', '-1');
      form.setFieldValue('grades', '-1');
    }
    if (watch('grades') !== '-1') {
      payload['grades'] = watch('grades');
    }
    dispatch(dashboardActions.actions.getUserList(payload));
  }, [watch('account_type')]);

  useEffect(() => {
    const payload = {};
    if (watch('grades') !== '-1') {
      payload['grades'] = watch('grades');
    }
    dispatch(dashboardActions.actions.getUserList(payload));
  }, [watch('grades')]);

  useEffect(() => {
    if (userList?.results) {
      setParentOptions(getReduceList(userList, 'parent'));
      setTeacherOptions(getReduceList(userList, 'teacher'));
      setStudentOptions(getReduceList(userList, 'student'));
    }
  }, [userList]);

  useEffect(() => {
    if (teacherList) setTeacherOptions(getReduceList(teacherList, 'teacher'));
    if (parentList) setParentOptions(getReduceList(parentList, 'parent'));
    if (studentList) setStudentOptions(getReduceList(studentList, 'student'));
  }, [parentList, studentList, teacherList]);

  return (
    <Modal
      open={open}
      title={<><GreenEllipse />  {"New Notification"} </>}
      onCancel={onCancel}
      closeIcon={<ModalCloseIcon />}
      footer={[
        <Button label={"Send"} variation="action" onClick={() => form.submit()} />,
      ]}
      className="custom-modal"
      destroyOnClose={true}
      {...props}
    >
      <Spin spinning={loading}>
        <Form
          preserve={false}
          layout="vertical"
          form={form}
          onFinish={onSendHandle}
          className="custom-add-form"
          key={"form"}
          initialValues={{
            account_type: '-1',
            grades: '-1',
            students: '-1',
            parents: '-1',
            teachers: '-1'
          }}
        >
          <Row gutter={20} key="first">
            <Col span={12}>
              <FormItem
                label="Account Type"
                name="account_type"
              >
                <Controller
                  name="account_type"
                  defaultValue="-1"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <Select
                      defaultValue={'-1'}
                      options={accountTypeOptions}
                      className="custom-select"
                      placeholder="Select Account Type"
                      onChange={onChange}
                      value={value}
                    />
                  )}
                />
              </FormItem>
            </Col>
            <Col span={12}>
              <FormItem
                label="Grade"
                name="grades"
              >
                <Controller
                  name="grades"
                  defaultValue="-1"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <Select
                      defaultValue={'-1'}
                      options={[{ value: '-1', label: 'All' }, ...gradeOptions]}
                      className="custom-select"
                      placeholder="Select Grade"
                      onChange={onChange}
                      value={value}
                      disabled={watch('account_type') === 'teacher'}
                    />
                  )}
                />
              </FormItem>
            </Col>
          </Row>
          <Row gutter={20} key="second">
            <Col span={8}>
              <FormItem label="Students" name="students">
                {/* <CustomMultiSelect
                  placeholder="Select Students"
                  selectedOptions={selectedOptions.students}
                  selectOptions={[{ value: '-1', label: 'All' }, ...gradeOptions]}
                  onChange={(value: string[]) => handleSelectedOptions(value, 'students')}
                  dirty={dirty}
                  setDirty={setDirty}
                /> */}
                <Select
                  defaultValue={'-1'}
                  options={studentOptions}
                  className="custom-select"
                  placeholder="Select Students"
                  disabled={watch('account_type') !== 'student' &&
                    watch('account_type') !== '-1'}
                />

              </FormItem>
            </Col>
            <Col span={8}>
              <FormItem
                label="Parents"
                name="parents"
              >
                {/* <CustomMultiSelect
                  placeholder="Select Parents"
                  selectedOptions={selectedOptions.parents}
                  selectOptions={[{ value: '-1', label: 'All' }, ...gradeOptions]}
                  onChange={(value: string[]) => { setSelectedOptions({ ...selectedOptions, parents: value }); }}
                  dirty={dirty}
                  setDirty={setDirty}
                /> */}
                <Select
                  defaultValue={'-1'}
                  options={parentOptions}
                  className="custom-select"
                  placeholder="Select Parents"
                  disabled={watch('account_type') !== 'parent' &&
                    watch('account_type') !== '-1'}
                />
              </FormItem>
            </Col>
            <Col span={8}>
              <FormItem
                label="Teachers"
                name="teachers"
              >
                {/* <CustomMultiSelect
                  placeholder="Select Teachers"
                  selectedOptions={selectedOptions.teachers}
                  selectOptions={[{ value: '-1', label: 'All' }, ...gradeOptions]}
                  onChange={(value: string[]) => { setSelectedOptions({ ...selectedOptions, teachers: value }); }}
                  dirty={dirty}
                  setDirty={setDirty}
                /> */}
                <Select
                  defaultValue={'-1'}
                  options={teacherOptions}
                  className="custom-select"
                  placeholder="Select Teacher"
                  disabled={watch('account_type') !== 'teacher' &&
                    watch('account_type') !== '-1'}
                />
              </FormItem>
            </Col>
          </Row>

          <Row gutter={20} key="third">
            <Col span={24}>
              <FormItem
                label="Title"
                name="title"
                rules={[{ required: true, message: 'Please enter the title!' }]}>
                <Input
                  placeholder="Write title"
                />
              </FormItem>
            </Col>
          </Row>
          <Row gutter={20} key="forth">
            <Col span={24}>
              <FormItem
                label="Content"
                name="message"
                rules={[{ required: true, message: 'Please enter the content!' }]}>
                <Input.TextArea
                  placeholder="Write content"
                  rows={4}
                  style={{ resize: 'none' }}
                />
              </FormItem>
            </Col>
          </Row>
          <Row>
            <Col>
              <FormItem
                name="attachment"
                label={<>Add Attachment <br />
                  <div style={{ opacity: '0.7', fontSize: '10px', paddingLeft: '4px' }}> (Accepted format - {AllowedFileFormat.join(', ')}. Max Size: 3MB)</div>
                </>}
                rules={[
                  {
                    validator: (_, value) => CustomFileValidator(value),
                  },
                ]}
                style={{ textAlign: 'left' }}
              >
                <Upload
                  beforeUpload={(file: UploadFile) => {
                    return handleFileUpload(file);
                    // return false;
                  }}
                  action={API_URL + "files/"}
                  onChange={(info) => {
                    if (info.file.status === 'done') {
                      // Handle response from API
                      console.log(info.file.response);
                      setAttachment(info.file.response?.id);
                    }
                  }}
                  headers={{ authorization: 'Token ' + auth.getToken() }}
                  onRemove={handleFileRemove}
                  fileList={file && file[0]?.file}
                  name="file"
                  multiple={false}
                  accept={AllowedFileFormat.join(',.') + ',.png'}
                >
                  {!file ? (
                    <Button
                      label={<><UploadOutlined /> Select File</>}
                      variation="action"
                      style={{ height: '48px', background: '#6B7579', border: '1px solid white' }}
                      type='button'
                    />
                  ) : null}
                </Upload>
              </FormItem>
            </Col>
          </Row>
        </Form>
      </Spin>
    </Modal >
  );
};
export default NotificationSendForm;

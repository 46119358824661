export const allOptions = {
  value: '-1', label: 'All'
};

export const getReduceList = (list, type) => {
  const options = list?.results?.reduce((options, item) => {
    if (item.account_type === type)
      options.push({ value: item.id, label: item.name || item.username });
    return options;
  }, [allOptions]);
  return options;
};

export const getListofId = (list: any[]) => {
  const result = list.filter(obj => obj.value !== '-1').map(obj => obj.value);
  return result;
};
/**
*
* PopoverContent
*
*/
import { Popover, PopoverProps, Space } from 'antd';
import "./styles.scss";

interface IProps extends PopoverProps {
}
const PopoverContent = ({ content, title, ...props }: IProps) => (
  <Space wrap>
    <Popover content={content} title={title} showArrow={false} trigger="click" {...props} >
      {props.children}
    </Popover>
  </Space>
);

export default PopoverContent;
// import { take, call, put, select, takeLatest } from 'redux-saga/effects';
import { dashboardActions as actions, dashboardActions } from '.';

import { call, delay, put, takeLatest } from "redux-saga/effects";
import { layoutActions } from '../../Layout/slice';
import { AxiosRequestConfig } from 'axios';
import request from '../../../../utils/request';
import { dataToFormData } from '../../../../utils/commonMethods';

function* fetchUserList({ payload }) {
  yield delay(500);
  yield put(layoutActions.setLoading(true));
  const { search = '', page, pageSize, grades, account_type, subjects } = payload;

  let url = `/users?search=${encodeURIComponent(
    search,
  )}${page ? '&page=' + page : ''}&pageSize=${pageSize}${grades ? '&grades=' + grades : ''}${subjects ? '&subjects=' + subjects : ''}${account_type ? '&user_type=' + account_type : ''}&sort_by_date=-`;
  const options: AxiosRequestConfig = {
    method: 'GET',
  };
  try {
    const response = yield call(
      request,
      url,
      options,
    );
    if (account_type === 'student')
      yield put(actions.setStudentList(response));
    else if (account_type === 'teacher')
      yield put(actions.setTeacherList(response));
    else if (account_type === 'parent')
      yield put(actions.setParentList(response));
    else
      yield put(actions.setUserList(response));
    yield put(layoutActions.setLoading(false));
  } catch (ex: any) {
    yield put(layoutActions.setError(ex.message));
    yield put(layoutActions.setLoading(false));
  }
}
function* fetchUserInformationById({ payload }) {
  yield delay(500);
  const { id } = payload;
  let url = `/users/${id}`;
  const options: AxiosRequestConfig = {
    method: 'GET',
  };
  try {
    const response = yield call(
      request,
      url,
      options,
    );
    yield put(actions.setUserInformationById(response));
  } catch (ex: any) {
    yield put(layoutActions.setError(ex.message));
  }
}
function* fetchParentsChildren({ payload }) {
  yield delay(500);
  const { id } = payload;
  let url = `/parents/${id}/children/`;
  const options: AxiosRequestConfig = {
    method: 'GET',
  };
  try {
    const response = yield call(
      request,
      url,
      options,
    );
    yield put(actions.setParentsChildren(response));
  } catch (ex: any) {
    yield put(layoutActions.setError(ex.message));
  }
}
function* fetchUserCounts() {
  yield delay(500);
  yield put(layoutActions.setLoading(true));

  let url = `/users/counts`;
  const options: AxiosRequestConfig = {
    method: 'GET',
  };
  try {
    const response = yield call(
      request,
      url,
      options,
    );
    yield put(actions.setUserCounts(response));
    yield put(layoutActions.setLoading(false));
  } catch (ex: any) {
    yield put(layoutActions.setError(ex.message));
    yield put(layoutActions.setLoading(false));
  }
}
// function* fetchTeacherList({ payload }) {
//   yield delay(500);
//   yield put(layoutActions.setLoading(true));
//   const { search, page, pageSize, grades, account_type = 'teacher' } = payload;

//   let url = `/users?search=${encodeURIComponent(
//     search,
//   )}&page=${page}&pageSize=${pageSize}${grades ? '&grades=' + grades : ''}&user_type=${account_type}`;
//   const options: AxiosRequestConfig = {
//     method: 'GET',
//   };
//   try {
//     const response = yield call(
//       request,
//       url,
//       options,
//     );

//     yield put(actions.setTeacherList(response));
//     yield put(layoutActions.setLoading(false));
//   } catch (ex: any) {
//     yield put(layoutActions.setError(ex.message));
//     yield put(layoutActions.setLoading(false));
//   }
// }
// function* fetchParentList({ payload }) {
//   yield delay(500);
//   yield put(layoutActions.setLoading(true));
//   const { search, page, pageSize, grades, account_type = 'parent' } = payload;

//   let url = `/users?search=${encodeURIComponent(
//     search,
//   )}&page=${page}&pageSize=${pageSize}${grades ? '&grades=' + grades : ''}&user_type=${account_type}`;
//   const options: AxiosRequestConfig = {
//     method: 'GET',
//   };
//   try {
//     const response = yield call(
//       request,
//       url,
//       options,
//     );

//     yield put(actions.setParentList(response));
//     yield put(layoutActions.setLoading(false));
//   } catch (ex: any) {
//     yield put(layoutActions.setError(ex.message));
//     yield put(layoutActions.setLoading(false));
//   }
// }
function* fetchCategoryList({ payload }) {
  yield delay(500);
  yield put(layoutActions.setLoading(true));
  const { search, page, pageSize, grades } = payload;

  let url = `/resources/categories?search=${encodeURIComponent(
    search,
  )}${page ? '&page=' + page : ''}${pageSize ? '&pageSize=' + pageSize : ''}${grades ? '&grades=' + grades : ''}`;
  const options: AxiosRequestConfig = {
    method: 'GET',
  };
  try {
    const response = yield call(
      request,
      url,
      options,
    );

    yield put(actions.setCategoryList(response));
    yield put(layoutActions.setLoading(false));
  } catch (ex: any) {
    yield put(layoutActions.setError(ex.message));
    yield put(layoutActions.setLoading(false));
  }
}

function* fetchSchoolClassList() {
  yield delay(500);
  yield put(layoutActions.setLoading(true));
  let url = `/schools/grades/`;
  const options: AxiosRequestConfig = {
    method: 'GET',
  };
  try {
    const response = yield call(
      request,
      url,
      options,
    );

    yield put(actions.setSchoolClassList(response));
    yield put(layoutActions.setLoading(false));
  } catch (ex: any) {
    yield put(layoutActions.setError(ex.message));
    yield put(layoutActions.setLoading(false));
  }
}

function* fetchSubjectList() {
  yield delay(500);
  let url = `/schools/subjects/`;
  const options: AxiosRequestConfig = {
    method: 'GET',
  };
  try {
    const response = yield call(
      request,
      url,
      options,
    );

    yield put(actions.setSubjectList(response));
  } catch (ex: any) {
    yield put(layoutActions.setError(ex.message));
  }
}

function* patchUserInformation({ payload }) {
  yield delay(500);
  yield put(layoutActions.setLoading(true));
  const { id, data, account_type } = payload;
  let url = `/users/${id}`;
  const options: AxiosRequestConfig = {
    method: 'PATCH',
    data: dataToFormData(data),
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  };
  try {
    yield call(request, url, options);

    yield put(actions.getUserList({
      search: '', page: 1, pageSize: 20, account_type: account_type
    }));
    yield put(layoutActions.setSuccess("User updated successfully"));
    yield put(layoutActions.setLoading(false));
  } catch (ex: any) {
    yield put(layoutActions.setError(ex.message));
    yield put(layoutActions.setLoading(false));
  }
}
function* updateUserStatusRequest({ payload }) {
  yield delay(500);
  yield put(layoutActions.setLoading(true));
  const { username, status } = payload;
  let url = `users/${username}/${status}/`;
  const options: AxiosRequestConfig = {
    method: 'PATCH',
  };
  try {
    const response = yield call(request, url, options);

    yield put(actions.getUserList({
      search: '', page: 1, pageSize: 10, account_type: response.account_type
    }));
    yield put(layoutActions.setLoading(false));
    yield put(layoutActions.setSuccess("User updated successfully"));
  } catch (ex: any) {
    yield put(layoutActions.setError(ex.message));
    yield put(layoutActions.setLoading(false));
  }
}

function* deleteUserInformationRequest({ payload }) {
  yield delay(500);
  yield put(layoutActions.setLoading(true));
  const { username, account_type } = payload;
  let url = `users/${encodeURIComponent(username)}/`;
  const options: AxiosRequestConfig = {
    method: 'DELETE',
  };
  try {
    const response = yield call(request, url, options);

    yield put(actions.getUserList({
      search: '', page: 1, pageSize: 10, account_type: account_type
    }));
    yield put(layoutActions.setLoading(false));
    yield put(layoutActions.setSuccess("User removed successfully"));
  } catch (ex: any) {
    yield put(layoutActions.setError(ex.message));
    yield put(layoutActions.setLoading(false));
  }
}

function* deleteSelectedUserRequest({ payload }) {
  yield delay(500);
  yield put(layoutActions.setLoading(true));
  const { user_list, account_type } = payload;
  let url = `users/delete-list/?user_list=${user_list.toString()}`;
  const options: AxiosRequestConfig = {
    method: 'DELETE',
  };
  try {
    const response = yield call(request, url, options);

    yield put(actions.getUserList({
      search: '', page: 1, pageSize: 10, account_type: account_type
    }));
    yield put(layoutActions.setLoading(false));
    yield put(layoutActions.setSuccess("User removed successfully"));
  } catch (ex: any) {
    yield put(layoutActions.setError(ex.message));
    yield put(layoutActions.setLoading(false));
  }
}

function* addNewTeacherSubmit({ payload }) {
  yield delay(500);
  yield put(layoutActions.setLoading(true));
  const { data } = payload;
  let url = `/teachers/create/`;
  const options: AxiosRequestConfig = {
    method: 'POST',
    data: data
  };
  try {
    yield call(request, url, options);
    yield put(actions.getUserList({
      search: '', page: 1, pageSize: 20, account_type: 'teacher'
    }));
    yield put(layoutActions.setLoading(false));
    yield put(actions.setIsAddedUser(true));
    yield put(layoutActions.setSuccess("Teacher added successfully"));
  } catch (ex: any) {
    yield put(layoutActions.setError(ex.message));
    yield put(layoutActions.setLoading(false));
  }
}

export function* dashboardSaga() {
  yield takeLatest(actions.getUserList, fetchUserList);
  yield takeLatest(actions.getCategoryList, fetchCategoryList);
  yield takeLatest(actions.getSchoolClassList, fetchSchoolClassList);
  yield takeLatest(actions.updateUserInformation, patchUserInformation);
  yield takeLatest(actions.updateUserStatus, updateUserStatusRequest);
  yield takeLatest(actions.deleteUserInformation, deleteUserInformationRequest);
  yield takeLatest(actions.deleteSelectedUser, deleteSelectedUserRequest);
  yield takeLatest(actions.getUserCounts, fetchUserCounts);
  yield takeLatest(actions.getUserInformationById, fetchUserInformationById);
  yield takeLatest(actions.getParentsChildren, fetchParentsChildren);
  yield takeLatest(actions.getSubjectList, fetchSubjectList);
  yield takeLatest(actions.addNewTeacherSubmit, addNewTeacherSubmit);
}

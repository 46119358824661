import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from 'utils/@reduxjs/toolkit';
import { useInjectReducer, useInjectSaga } from 'utils/redux-injectors';
import { resourcesSaga } from './saga';
import { ResourcesResponse, ResourcesState, UpdateResourceRequest } from './types';

export const initialState: ResourcesState = {
  resourcesList: null,
  isAddedResources: null
};

const slice = createSlice({
  name: 'resources',
  initialState,
  reducers: {
    setAddedResource(state, action: PayloadAction<boolean | null>) {
      state.isAddedResources = action.payload;
    },
    getResourcesList(state, action: PayloadAction<any>) { },
    setResourcesList(state, action: PayloadAction<ResourcesResponse | null>) {
      state.resourcesList = action.payload;
    },
    deleteResourceById(state, action: PayloadAction<{ id: string | number; }>) { },
    updateResourceById(state, action: PayloadAction<{ id: string | number, data: UpdateResourceRequest; }>) { },
    addNewResource(state, action: PayloadAction<{ data: UpdateResourceRequest; }>) { },
  },
});

export const { actions: resourcesActions } = slice;

export const useResourcesSlice = () => {
  useInjectReducer({ key: slice.name, reducer: slice.reducer });
  useInjectSaga({ key: slice.name, saga: resourcesSaga });
  return { actions: slice.actions };
};

/**
 * Example Usage:
 *
 * export function MyComponentNeedingThisSlice() {
 *  const { actions } = useResourcesSlice();
 *
 *  const onButtonClick = (evt) => {
 *    dispatch(actions.someAction());
 *   };
 * }
 */
import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from 'utils/@reduxjs/toolkit';
import { useInjectReducer, useInjectSaga } from 'utils/redux-injectors';
import { feedbackSaga } from './saga';
import { FeedbackReplyRequest, FeedBackResponse, FeedbackState } from './types';

export const initialState: FeedbackState = {
  feedbackList: null
};

const slice = createSlice({
  name: 'feedback',
  initialState,
  reducers: {
    getFeedbackList(state, action: PayloadAction<any>) { },
    setFeedbackList(state, action: PayloadAction<FeedBackResponse | null>) {
      state.feedbackList = action.payload;
    },
    sendFeedbackReply(state, action: PayloadAction<FeedbackReplyRequest>) { }
  },
});

export const { actions: feedbackActions } = slice;

export const useFeedbackSlice = () => {
  useInjectReducer({ key: slice.name, reducer: slice.reducer });
  useInjectSaga({ key: slice.name, saga: feedbackSaga });
  return { actions: slice.actions };
};

/**
 * Example Usage:
 *
 * export function MyComponentNeedingThisSlice() {
 *  const { actions } = useFeedbackSlice();
 *
 *  const onButtonClick = (evt) => {
 *    dispatch(actions.someAction());
 *   };
 * }
 */
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import * as ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { ConfigProvider } from 'antd';
import { configureAppStore } from './store/configureStore';
// import 'antd/dist/antd.css';
// Use consistent styling
import 'sanitize.css/sanitize.css';
import './styles/global.scss';

import { App } from './app';
import reportWebVitals from './reportWebVitals';
import { AliasToken } from 'antd/es/theme/internal';
import { BrowserRouter } from 'react-router-dom';
import { PersistGate } from 'redux-persist/integration/react';

const MOUNT_NODE = document.getElementById('root') as HTMLElement;
const { store, persistor } = configureAppStore();

interface ThemeData extends Partial<AliasToken> {
  borderRadius: number;
  colorPrimary: string;

};

const defaultData: ThemeData = {
  borderRadius: 5,
  colorPrimary: '#7E8F52',
  colorError: '#C10505'
};

ReactDOM.render(
  <Provider store={store}>
    <ConfigProvider
      theme={{
        token: defaultData
      }}
    >
      {/* <PersistGate loading={null} persistor={persistor}> */}
      <BrowserRouter>
        <App />
      </BrowserRouter>
      {/* </PersistGate> */}
    </ConfigProvider>
  </Provider>
  ,
  MOUNT_NODE,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

/**
*
* Login
*
*/

import { Button, Checkbox, Form, Input, notification, Space, Spin } from "antd";
import Icon from '@ant-design/icons';
import { Link, useHistory } from "react-router-dom";
import { EmailIcon, LockIcon } from "../../components/icons";
import { useDispatch, useSelector } from "react-redux";
import { useLoginSlice } from "./slice";
import { IFormFields } from "./slice/types";
import { useEffect } from "react";
import { selectLoading, selectLoginError, selectLoginSuccess } from "./slice/selectors";
import CompanyLogo from "../../../assets/icons/company-logo.svg";
import "./style.scss";


interface Props { }

/* eslint-disable no-template-curly-in-string */
const validateMessages = {
  types: {
    email: '${label} is not a valid email!',
  },
  required: '${label} is required!',
};

export function Login(props: Props) {
  const dispatch = useDispatch();
  const history = useHistory();

  const { actions } = useLoginSlice();
  const loading = useSelector(selectLoading);
  const error = useSelector(selectLoginError);
  const success = useSelector(selectLoginSuccess);

  const onFinish = (values: IFormFields) => {
    dispatch(actions.login(values));
  };

  useEffect(() => {
    if (error) {
      notification.error({
        message: 'Login Failed',
        description: error,
      });
      dispatch(actions.setLoginError(null));
    }
  }, [error]);

  useEffect(() => {
    if (success) {
      history.push("/");
      dispatch(actions.setSuccess(false));
    }
  }, [success]);

  return (
    <Spin spinning={loading}>
      <div className="login-main">
        <Space direction="vertical" size="large" style={{ marginBottom: '75px' }}>
          <img src={CompanyLogo} alt="Logo" />
          <p className="heading">Welcome to<br />
            Heartland Charter School.
          </p>
          <span className="subtitle">Log in to your Account</span>
        </Space>
        <Form
          name="normal_login"
          className="login-form"
          initialValues={{ remember: true }}
          onFinish={onFinish}
          validateMessages={validateMessages}
        >
          <Form.Item
            name="username"
            rules={[{ type: 'email', required: true, message: 'Please input your Email!' }]}
          >
            <Input prefix={<Icon component={EmailIcon} className="site-form-item-icon" />} placeholder="Email" autoComplete="new-password" type="email" />
          </Form.Item>
          <Form.Item
            name="password"
            rules={[{ required: true, message: 'Please input your Password!' }]}
            style={{ marginBottom: '12px' }}
          >
            <Input.Password
              prefix={<Icon component={LockIcon} className="site-form-item-icon" />}
              type="password"
              placeholder="Password"
              autoComplete="new-password"
            />
          </Form.Item>
          <Form.Item name="remember" valuePropName="checked" noStyle>
            <div className="login-form-remember">
              <Checkbox defaultChecked>Remember me</Checkbox>
            </div>
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit" className="login-form-button">
              Log in
            </Button>
          </Form.Item>
          {/* <Link className="login-form-forgot" to={"/change-password"}>
            Forgot Password?
          </Link> */}
        </Form>
      </div>
    </Spin>
  );
};


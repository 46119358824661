/**
*
* Notifications
*
*/
import { Col, Row, Table, Form } from 'antd';
import dayjs from 'dayjs';
import React, { memo, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { auth } from '../../../utils/auth';
import Button from '../../components/Button';
import PageTitle from '../../components/Header/PageTitle';
import SearchInput from '../../components/Header/SearchInput';
import { GreenEllipse } from '../../components/icons';
import DeleteIcon from '../../components/icons/DeleteIcon';
import Modal from '../../components/Modal';
import { useDashboardSlice } from '../Dashboard/slice';
import { selectDashboard } from '../Dashboard/slice/selectors';
import { Layout } from '../Layout';
import { selectLayout } from '../Layout/slice/selectors';
import NotificationSendForm from './components/NotificationSendForm';
import { useNotificationsSlice } from './slice';
import { selectNotifications } from './slice/selectors';
import { NotificationDetails, SendNotificationRequest } from './slice/types';

interface Props { }

export const Notifications = memo((props: Props) => {
  const dispatch = useDispatch();
  const { notificationList, isAddedNotification } = useSelector(selectNotifications);
  const { schoolClassList } = useSelector(selectDashboard);
  const { actions } = useNotificationsSlice();
  const dashboardActions = useDashboardSlice();
  const { loading } = useSelector(selectLayout);
  const [search, setSearch] = useState('');
  const [page, setPage] = useState(1);
  const [pageSize] = useState(20);
  const [modalDetails, setModalDetails] = useState({
    modalId: null,
    isVisible: false
  });
  const [openFormModal, setOpenFormModal] = useState(false);
  const [form] = Form.useForm();
  const accountTypeOptions = [
    {
      value: '-1', label: 'All'
    },
    {
      value: 'student', label: 'Student'
    },
    {
      value: 'parent', label: 'Parent'
    },
    {
      value: 'teacher', label: 'Teacher'
    }
  ];

  const handleSearch = (val: React.SetStateAction<string>) => {
    setSearch(val);
  };

  const handleResendNotification = (notificationDetails: NotificationDetails) => {
    dispatch(actions.resendNotifications({ data: notificationDetails, id: notificationDetails.id }));
  };
  const handleSendNotification = (notificationDetails: SendNotificationRequest) => {
    dispatch(actions.sendNotifications({ data: notificationDetails }));
  };
  const handleCancel = () => {
    setModalDetails({
      modalId: null,
      isVisible: false
    });
  };
  const handleOk = () => {
    dispatch(actions.deleteNotificationById({ id: modalDetails.modalId }));
    setModalDetails({
      modalId: null,
      isVisible: false
    });
  };



  // UseEffects
  useEffect(() => {
    dispatch(actions.getNotificationList({
      search: search, page: page, pageSize: pageSize
    }));
    dispatch(dashboardActions.actions.getSchoolClassList());

  }, []);
  useEffect(() => {
    if (isAddedNotification) {
      setOpenFormModal(false);
      form.resetFields();
      dispatch(actions.setIsAddedNotification(null));
    }
  }, [isAddedNotification]);
  useEffect(() => {
    dispatch(actions.getNotificationList({
      search: search, page: page, pageSize: pageSize
    }));
  }, [search, page]);

  const columns = [
    {
      title: 'Title',
      dataIndex: 'title',
      width: '20%',
    },
    {
      title: 'Content',
      dataIndex: 'message',
      width: '35%',
    },
    {
      title: 'Time',
      dataIndex: 'time',
      width: '15%',
      render: (_, row) => <div>{dayjs(row.created_at).format('HH:mm')}</div>
    },
    {
      title: 'Date',
      dataIndex: 'date',
      width: '15%',
      render: (_, row) => <div>{dayjs(row.created_at).format('MM/DD/YYYY')}</div>
    },
    {
      title: 'Action',
      render: (_: any, row) => {
        return (
          <div className='table-action-btn'>
            <Button
              key={`action-${row.id}`}
              label="Resend"
              variation='secondary'
              onClick={() =>
                handleResendNotification(row)
              }
            />
            <div
              className='search-icon' onClick={() => {
                setModalDetails({
                  isVisible: true,
                  modalId: row.id
                });
              }}>
              <DeleteIcon />
            </div>
          </div>
        );
      },
      width: '15%',
    },
  ];

  return (
    <Layout>
      <Row className="header-container flex--justify-between" style={{ marginTop: '16px' }}>
        <Col span={6}>
          <PageTitle title={"Notifications"} subtitle={''} titleIcon={<GreenEllipse />} />
        </Col>
        <Col span={12}>
          <SearchInput handleSearch={handleSearch} />
        </Col>
        <Col span={5}>
          <Button
            label={<span> New Notification</span>}
            variation="action"
            style={{ width: '100%', height: '48px', background: '#424242' }}
            onClick={() => setOpenFormModal(prev => !prev)}
          />
        </Col>
      </Row>
      <Row className='students-main-container'>
        <Col span={24}>
          <Table
            tableLayout='fixed'
            loading={loading}
            columns={columns}
            dataSource={notificationList?.results || []}
            pagination={{
              current: page,
              total: notificationList?.count || 0,
              onChange(page) {
                setPage(page);
              },
              pageSize: pageSize
            }}
            rowKey={obj => obj.id.toString()}
          />
        </Col>
      </Row>
      <Modal
        handleCancel={handleCancel}
        handleOk={handleOk}
        isModalVisible={modalDetails.isVisible}
        message={"Are you sure you want to delete this notification?"}
        title={"Notification Delete"}
        btnTitle={"Delete"}
      />
      {
        openFormModal &&
        <NotificationSendForm
          onFinish={handleSendNotification}
          open={openFormModal}
          form={form}
          onCancel={() => {
            setOpenFormModal(false);
            form.setFieldsValue({});
          }}
          gradeOptions={schoolClassList?.results?.map(item => { return { value: item.id, label: item.name }; })}
          accountTypeOptions={accountTypeOptions}
          loading={loading}
        />
      }
    </Layout >
  );

});


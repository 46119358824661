const CategoriesIcon = (props: { color?: string; }) => {
  const { color = 'white' } = props;
  return (
    <svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g opacity="0.7">
        <path d="M0.00317383 6.03941C0.00317383 4.681 0.00317383 3.3176 0.00317383 1.95919C0.00317383 0.835505 0.772273 0.0664062 1.89596 0.0664062C3.42916 0.0664062 4.96736 0.0664062 6.50056 0.0664062C7.62425 0.0664062 8.39334 0.835505 8.39334 1.95919C8.39334 4.69099 8.39334 7.42778 8.39334 10.1596C8.39334 11.2833 7.62425 12.0524 6.50056 12.0524C4.96736 12.0524 3.42916 12.0524 1.89596 12.0524C0.772273 12.0524 0.00317383 11.2833 0.00317383 10.1596C0.00317383 8.78619 0.00317383 7.4128 0.00317383 6.03941ZM7.19475 6.08436C7.19475 4.71096 7.19475 3.33757 7.19475 1.96418C7.19475 1.49473 6.96502 1.265 6.49057 1.265C4.96236 1.265 3.43415 1.265 1.90095 1.265C1.42651 1.265 1.19678 1.48974 1.19678 1.96418C1.19678 4.69099 1.19678 7.41779 1.19678 10.1496C1.19678 10.634 1.42151 10.8588 1.90594 10.8588C3.42916 10.8588 4.95238 10.8588 6.47559 10.8588C6.96002 10.8588 7.18976 10.634 7.18976 10.1496C7.19475 8.79119 7.19475 7.43777 7.19475 6.08436Z" fill={color} />
        <path d="M17.9821 12.0716C17.9821 13.425 17.9821 14.7784 17.9821 16.1368C17.9821 17.2854 17.223 18.0495 16.0693 18.0495C14.5461 18.0495 13.0229 18.0495 11.4997 18.0495C10.351 18.0495 9.58691 17.2904 9.58691 16.1368C9.58691 13.415 9.58691 10.6932 9.58691 7.97135C9.58691 6.8227 10.346 6.05859 11.4997 6.05859C13.0229 6.05859 14.5461 6.05859 16.0693 6.05859C17.218 6.05859 17.9771 6.8177 17.9821 7.97135C17.9871 9.33476 17.9821 10.7032 17.9821 12.0716ZM16.7835 12.0716C16.7835 10.6932 16.7835 9.31478 16.7835 7.9314C16.7835 7.49691 16.5438 7.25719 16.1143 7.25719C14.5661 7.25719 13.0179 7.25719 11.4697 7.25719C11.0252 7.25719 10.7905 7.49192 10.7905 7.94139C10.7905 10.6832 10.7905 13.42 10.7905 16.1618C10.7905 16.6062 11.0252 16.846 11.4697 16.846C13.0129 16.846 14.5511 16.846 16.0943 16.846C16.5538 16.846 16.7835 16.6112 16.7835 16.1518C16.7885 14.7934 16.7835 13.43 16.7835 12.0716Z" fill={color} />
        <path d="M13.7721 4.85922C13.003 4.85922 12.2389 4.85922 11.4698 4.85922C10.3711 4.85922 9.59701 4.08513 9.59701 2.98641C9.59701 2.50698 9.57203 2.02254 9.63696 1.5481C9.74683 0.71907 10.486 0.0798189 11.32 0.0698306C12.9681 0.0548481 14.6161 0.0548481 16.2642 0.0698306C17.1581 0.0798189 17.9272 0.813959 17.9772 1.71291C18.0072 2.25727 18.0171 2.81661 17.9472 3.35598C17.8423 4.21997 17.0932 4.84424 16.2242 4.85423C15.4052 4.86421 14.5862 4.85922 13.7721 4.85922ZM13.7921 3.66062C14.5762 3.66062 15.3652 3.66062 16.1493 3.66062C16.5339 3.66062 16.7836 3.41092 16.7836 3.02637C16.7886 2.65181 16.7886 2.27724 16.7836 1.90268C16.7786 1.50815 16.5339 1.26343 16.1343 1.26343C14.5662 1.26343 13.003 1.26343 11.4349 1.26343C11.0353 1.26343 10.7906 1.50815 10.7906 1.90268C10.7906 2.26226 10.7906 2.62684 10.7906 2.98641C10.7906 3.41591 11.0303 3.65563 11.4648 3.65563C12.2439 3.66062 13.018 3.66062 13.7921 3.66062Z" fill={color} />
        <path d="M4.19329 18.0456C3.41919 18.0456 2.6451 18.0456 1.87101 18.0456C0.782284 18.0456 0.00319625 17.2715 0.00319625 16.1878C0.00319625 15.7034 -0.0167803 15.209 0.0431495 14.7295C0.148027 13.9105 0.887161 13.2662 1.7112 13.2612C3.36426 13.2463 5.01732 13.2463 6.67039 13.2612C7.55934 13.2712 8.31846 13.9904 8.37339 14.8893C8.40835 15.4387 8.41334 16.003 8.34842 16.5524C8.24354 17.4114 7.49442 18.0356 6.62044 18.0456C5.81639 18.0506 5.00733 18.0456 4.19329 18.0456ZM4.20328 16.847C4.98736 16.847 5.77643 16.847 6.56051 16.847C6.94506 16.847 7.19477 16.5973 7.19477 16.2128C7.19977 15.8382 7.19977 15.4637 7.19477 15.0891C7.18978 14.6946 6.94506 14.4498 6.54553 14.4498C4.97737 14.4498 3.4142 14.4498 1.84604 14.4498C1.44651 14.4498 1.20179 14.6946 1.20179 15.0891C1.20179 15.4487 1.20179 15.8132 1.20179 16.1728C1.20179 16.6023 1.44151 16.842 1.876 16.842C2.65509 16.847 3.42918 16.847 4.20328 16.847Z" fill={color} />
      </g>
    </svg>
  );
};
export default CategoriesIcon;
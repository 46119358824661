import { AxiosRequestConfig } from 'axios';
import { take, call, put, select, takeLatest, delay } from 'redux-saga/effects';
import { categoriesActions as actions } from '.';
import request from '../../../../utils/request';
import { dashboardActions } from '../../Dashboard/slice';
import { layoutActions } from '../../Layout/slice';

function* addNewCategories({ payload }) {
  const { data } = payload;
  yield delay(500);
  yield put(layoutActions.setLoading(true));
  const options: AxiosRequestConfig = {
    method: 'POST',
    data: data
  };
  try {
    yield call(
      request,
      `/resources/categories/`,
      options,
    );
    yield put(layoutActions.setSuccess("New category added successfully"));
    yield put(dashboardActions.getCategoryList({
      search: '', page: 1, pageSize: 20
    }));
    yield put(layoutActions.setLoading(false));
  } catch (ex: any) {
    yield put(layoutActions.setError(ex.message));
    yield put(layoutActions.setLoading(false));
  }
}

function* deleteCategoryById({ payload }) {
  yield delay(500);
  yield put(layoutActions.setLoading(true));
  const { id } = payload;
  let url = `/resources/categories/${id}`;
  const options: AxiosRequestConfig = {
    method: 'DELETE',
  };
  try {
    yield call(request, url, options);
    yield put(dashboardActions.getCategoryList({
      search: '', page: 1, pageSize: 20
    }));
    yield put(layoutActions.setLoading(false));
    yield put(layoutActions.setSuccess("Category removed successfully"));
  } catch (ex: any) {
    yield put(layoutActions.setError(ex.message));
    yield put(layoutActions.setLoading(false));
  }
}

export function* categoriesSaga() {
  yield takeLatest(actions.addNewCategories, addNewCategories);
  yield takeLatest(actions.deleteCategoryById, deleteCategoryById);
}
